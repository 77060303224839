<template>
     <v-dialog
       v-model="openDialog"
       width="500"
       @keydown.esc="$emit('no')"
       @keydown.enter="$emit(yesFirst ? 'yes' : 'no')"
       @click:outside="$emit('no')"
       >
       <v-card>
         <v-card-title light class="text-h5 white" style="color: #154985">
           {{ title }}
         </v-card-title>
         <v-card-text>
           {{ description }}
               <v-row dense :class="{ errorSection: true, active: error !== '', inactive: error === '' }">
                 <v-col cols="12" style="color: red;" >
                   {{ error !== '' ? `Error: ${error}` : '' }}
                 </v-col>
               </v-row>
         </v-card-text>
         <v-divider></v-divider>

         <v-card-actions>
           <v-btn
             :color="yesFirst ? 'primary' : 'secondary'"
             text
             @click="$emit(yesFirst ? 'yes' : 'no')"
             >
               {{ yesFirst ? yesText : noText }}
           </v-btn>
           <v-spacer></v-spacer>
           <v-btn
             :color="yesFirst ? 'secondary' : 'primary'"
             text
             @click="$emit(yesFirst ? 'no' : 'yes')"
             >
               {{ yesFirst ? noText : yesText }}
           </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
</template>

<script>
  import { defineComponent, computed } from 'vue';
  export default defineComponent({
    name: 'confirmDialog',
    props: {
      showDialog: {
        type: Boolean,
        value: false,
      },
      title: {
        type: String,
        default: 'Are you sure?',
      },
      yesText: {
        type: String,
        default: 'Yes',
      },
      noText: {
        type: String,
        default: 'No',
      },
      description: {
        type: String,
        default: 'No',
      },
      yesFirst: {
        type: Boolean,
        default: false,
      },
      error: {
        type: String,
        default: '',
      },

    },
    setup(props) {
      const openDialog = computed(() => props.showDialog ? true : false);
      return { openDialog };
    },
});
</script>
<style lang="scss" scoped>
@keyframes inactive {
  from {height: 0px; opacity: 100; }  
  to {height: 25px; opacity: 0; }
}
.errorSection {
  height: 0px;
  opacity: 0;
}
.inactive {
  height: 0px;
  opacity: 0;
  animation-name: inactive;
  animation-duration: 200ms;
}
.active {
  animation-name: inactiveError;
  animation-duration: 200ms;
  height: 25px;
  opacity: 100;
}
@keyframes inactiveError {
  from {height: 0px; opacity: 0; }  
  to {height: 25px; opacity: 100; }
}

</style>
