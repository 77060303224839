import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#015EBA',
        secondary: '#AA0404',
        anchor: '#00f',
      },
    }
  },
});
export default vuetify
