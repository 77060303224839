import { getRule } from '@/utils/rules';
const plantingsSchema = [
  {
    label: 'Allocations',
    value: 'allocations',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
  },
  {
    label: 'Acres',
    value: 'acres',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Type',
    value: 'type',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Variety',
    value: 'variety',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Spacing',
    value: 'spacing',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Rootstock',
    value: 'rootstock',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Year Planted',
    value: 'yearPlanted',
    type: 'year',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Age',
    value: 'age',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Condition',
    value: 'condition',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Yield',
    value: 'yield',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
];
export default plantingsSchema;
