<template>
  <v-card class="ma-6">
    <v-row class="mb-5 topRow" dense>
      <v-col cols="10" class="pl-4 heading" dense>
        {{ title }}
      </v-col>
      <v-col cols="1">
        <v-tooltip bottom color="blue darken-4">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              outlined
              color="blue darken-4"
              v-bind="attrs"
              v-on="on"
              @click="$emit('addCustomField')"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          Add Custom {{ title }} Fields
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" dense style="margin-top: -20px">
        <v-progress-linear
          absolute
          :active="loading"
          indeterminate
          color="blue darken-4"
          value="100"
        ></v-progress-linear>
        <div
          v-if="!loading"
          style="
            width: 100%;
            height: 2px;
            background: #496688;
            margin-top: 1px 0 1px;
          "
        ></div>
      </v-col>
    </v-row>
    <v-row
      dense
      :class="{
        errorSection: true,
        active: errorStatus !== '',
        inactive: errorStatus === '',
      }"
      style="overflow-x:auto;"
    >
      <v-col cols="10" class="pl-4 pt-2" style="color: red;">
        {{ errorStatus !== "" ? `Error: ${errorStatus}` : "" }}
      </v-col>
      <v-col cols="1">
        <v-tooltip bottom color="red">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="errorStatus !== ''"
              icon
              @click="$emit('emitError', '')"
              color="red darken-1"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </template>
          Close Error
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        :cols="item.cols ? item.cols : 6"
        v-for="(item, li) in fSchema"
        :key="li"
        dense
      >
        <v-textarea
          v-if="item.type === 'textarea'"
          v-model="iData[item.value]"
          :rules="item.rules"
          :label="item.label"
          outlined
          dense
          class="pa-1 ma-1"
          @change="$emit('update', item.value, iData[item.value], data.id, data.record)"
        ></v-textarea>
        <v-menu
          v-else-if="item.type === 'datepicker'"
          :close-on-content-click="true"
          :nudge-right="40"
          :return-value.sync="item.value"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :lazy="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="iData[item.value]"
              :label="item.label"
              append-icon="mdi-calendar-plus"
              @click:append="on.click"
              outlined
              dense
              class="pa-1 ma-1"
              v-bind="attrs"
              v-on="on"
              @change="$emit('update', item.value, iData[item.value], data.id, data.record)"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="en-us"
            v-model="iData[item.value]"
            no-title
            scrollable
            @change="$emit('update', item.value, iData[item.value], data.id, data.record)"
          ></v-date-picker>
        </v-menu>
        <v-checkbox
          v-else-if="item.type === 'checkbox'"
          v-model="iData[item.value]"
          :label="item.label"
          dense
          class="pa-1 ma-1"
          @change="$emit('update', item.value, iData[item.value], data.id, data.record)"
        ></v-checkbox>
        <v-text-field
          v-else
          v-model="iData[item.value]"
          :rules="item.rules"
          :label="item.label"
          :prefix="item.prefix"
          :suffix="item.suffix"
          outlined
          dense
          class="pa-1 ma-1"
          @change="$emit('update', item.value, iData[item.value], data.id, data.record)"
        ></v-text-field>
      </v-col>
      <v-col
        :cols="data.cols ? item.cols : 6"
        v-for="(item, lj) in data.custom"
        :key="lj + schema.length"
        dense
      >
        <v-textarea
          v-if="item.type === 'textarea'"
          v-model="iData['custom'][lj]['value']"
          :rules="item.rules"
          :label="item.name"
          outlined
          dense
          class="pa-1 ma-1"
          @change="$emit('updateCustomField', item)"
        ></v-textarea>
        <v-menu
          v-else-if="item.type === 'datepicker'"
          :close-on-content-click="true"
          :nudge-right="40"
          :return-value.sync="item.value"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :lazy="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="iData['custom'][lj]['value']"
              :label="item.name"
              append-icon="mdi-calendar-plus"
              outlined
              dense
              class="pa-1 ma-1"
              v-bind="attrs"
              v-on="on"
              @change="$emit('updateCustomField', item)"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="iData['custom'][lj]['value']"
            no-title
            scrollable
            @change="$emit('updateCustomField', item)"
          ></v-date-picker>
        </v-menu>
        <v-checkbox
          v-else-if="item.type === 'checkbox'"
          v-model="iData['custom'][lj]['value']"
          :label="item.name"
          dense
          class="pa-1 ma-1"
          @change="$emit('updateCustomField', item)"
        ></v-checkbox>
        <v-text-field
          v-else
          v-model="iData['custom'][lj]['value']"
          :rules="item.rules"
          :label="item.name"
          :prefix="item.prefix"
          :suffix="item.suffix"
          outlined
          dense
          class="pa-1 ma-1"
          @change="$emit('updateCustomField', item)"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { defineComponent, ref, onMounted, watch } from "vue";

export default defineComponent({
  name: "BubbleView",
  props: {
    data: {
      type: Object,
      required: true,
    },
    schema: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Title Goes Here",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    subCategory: {
      type: String,
      default: "",
    },
    errorStatus: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const iData = ref({ ...props.data });
    const fSchema = ref(
      props.schema.filter((i) => {
        //console.log('i');
        const { subCategories } = i;
        //console.log(subCategories);
        return subCategories.reduce((j, k) => {
          if (j === true || k === "*" || k === props.subCategory) return true;
          return false;
        }, false);
      })
    );
    const emitCustomItem = (item) => {
      console.log("emit Custom Item");
      console.log(item);
    };
    onMounted(() => {
      // console.log(`re mount bubbleView ${props.subCategory}`)
      iData.value = { ...props.data };
      console.log(`schemacount ${props.subCategory} => ${props.title} ${props.schema.length}`);
      fSchema.value = props.schema.filter((i) => {
        const { subCategories } = i;
        //console.log(subCategories);
        return subCategories.reduce((j, k) => {
          if (j === true || k === "*" || k === props.subCategory) return true;
          return false;
        }, false);
      });
    });
    watch(props,(newData) => {
      const { data } = newData;
      if(data) {
        iData.value = { ...data };
      }
    });
    const clickCalendar = (event, data) => {
      console.log('clickCalendar');
      console.log(event);
      console.log(data);
    }
    return {
      clickCalendar,
      emitCustomItem,
      iData,
      fSchema,
    };
  },
});
</script>
<style lang="scss" scoped>
@keyframes inactive {
  from {
    height: 0px;
    opacity: 100;
  }
  to {
    height: 60px;
    opacity: 0;
  }
}
.errorSection {
  height: 0px;
  opacity: 0;
}
.inactive {
  height: 0px;
  opacity: 0;
  animation-name: inactive;
  animation-duration: 200ms;
}
.active {
  animation-name: inactiveError;
  animation-duration: 200ms;
  height: 60px;
  opacity: 100;
}
@keyframes inactiveError {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 60px;
    opacity: 100;
  }
}
</style>
