<template>
     <v-dialog
       v-model="openDialog"
       width="500"
       @keydown.esc="$emit('close')"
       @click:outside="$emit('close')"
       >
       <v-card>
         <v-card-title light class="text-h5 white" style="color: #154985">
           Select a Comp Type
         </v-card-title>
         <v-card-text>
           <v-row dense>
             <v-col cols="12">
               <v-progress-linear absolute :active="loading" indeterminate color="blue darken-4" value="100"></v-progress-linear>
               <div v-if="!loading" style="width: 100%; height: 2px; background: #496688; margin-top: 1px 0 1px;"></div>
             </v-col>
           </v-row>
           <v-row dense :class="{ errorSection: true, active: errorStatus !== '', inactive: errorStatus === '' }">
             <v-col cols="10" class="pl-4 pt-2" style="color: red; overflow:auto; height:60px;" >
               {{ errorStatus !== '' ? `Error: ${errorStatus}` : '' }}
             </v-col>
             <v-col cols="1">
               <v-tooltip bottom color="red">
                 <template v-slot:activator="{ on, attrs }">
                   <v-btn v-show="errorStatus !== ''" icon @click="closeError" color="red darken-1" v-bind="attrs" v-on="on">
                     <v-icon>
                       mdi-close
                     </v-icon>
                   </v-btn>
                 </template>
                 Close Error
               </v-tooltip>
             </v-col>
           </v-row>
           <v-row justify="center">
             <v-col v-for="itemsKey in itemsKeys" :key="itemsKey" cols="6" style="text-align:center">
               {{ itemsKey }}
             </v-col>
           </v-row>
           <v-row v-for="li in numRows" :key="li" justify="center">
             <v-col v-for="itemsKey in itemsKeys" :key="itemsKey" cols="6" style="text-align:center">
               <v-btn v-if="li - 1 in items[itemsKey]" rounded filled color="blue lighten-3" block @click="$emit('selectCompType', itemsKey, items[itemsKey][li - 1])">
                 {{ items[itemsKey][li - 1] }}
               </v-btn>
             </v-col>
           </v-row>
         </v-card-text>
         <v-divider></v-divider>

         <v-card-actions>
           <v-btn
             color="secondary"
             text
             @click="$emit('close')"
             >
               Cancel
           </v-btn>
           <v-spacer></v-spacer>
         </v-card-actions>
       </v-card>
     </v-dialog>
</template>

<script>
  import { defineComponent, computed } from 'vue';
  export default defineComponent({
    name: 'selectCompType',
    props: {
      showDialog: {
        type: Boolean,
        value: false,
      },
      items: {
        type: Object,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      errorStatus: {
        type: String,
        default: '',
      }
    },
    setup(props, { emit }) {
      const openDialog = computed(() => props.showDialog ? true : false);
      const itemsKeys = computed(() => Object.keys(props.items));
      const numRows = computed (() => Object.keys(props.items).map(i => props.items[i]).reduce((i,j) => { //Maximum rows to render
        if(i.length > j.length) {
          return i.length;
        }
        return j.length;
      }));
      const closeError = () => {
        console.log('close error');
        emit('emitError', '');
      }
      return {
        closeError,
        numRows,
        openDialog,
        itemsKeys,
      };
    },
});
</script>
<style lang="scss" scoped>
@keyframes inactive {
  from {height: 0px; opacity: 100; }  
  to {height: 45px; opacity: 0; }
}
.errorSection {
  height: 0px;
  opacity: 0;
}
.inactive {
  height: 0px;
  opacity: 0;
  animation-name: inactive;
  animation-duration: 200ms;
}
.active {
  animation-name: inactiveError;
  animation-duration: 200ms;
  height: 45px;
  opacity: 100;
}
@keyframes inactiveError {
  from {height: 0px; opacity: 0; }  
  to {height: 45px; opacity: 100; }
}

</style>
