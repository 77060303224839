export const removeCommas = (value) => {
  if(typeof(value) == 'string') {
    return value.replaceAll(',','');
  }
  return value;
  
}
export const getRule = (type) => {
  switch(type) {
    case 'number':
      return [v => Number.isInteger(parseInt(removeCommas(v))) || v === '' || v === null || typeof(v) === 'undefined' || 'Must be a number'];
    case 'money':
      //return [v => /^\d+(\.\d{1,2})?$/.test(parseFloat(removeCommas(v))) || v === ''  || v === null || typeof(v) === 'undefined' || 'Must be a number with 2 decimal places'];
    return [v => /^\d+(\.\d{1,2})?$/.test(parseFloat(removeCommas(v))) || /^-\d+(\.\d{1,2})?$/.test(parseFloat(removeCommas(v)))  || v === ''  || v === null || typeof(v) === 'undefined' || 'Must be a number with 2 decimal places'];
    case 'decimal':
    case 'percent':
      return [v => !isNaN(parseFloat(removeCommas(v))) || v === '' || v === null || typeof(v) === 'undefined' || `Must be a number "${v}"`];
    case 'zip':
      return [v => /^\d{5}(?:[-\s]\d{4})?$/.test(v) || v === '' || v === null || typeof(v) === 'undefined' || 'Must be a valid zip code'];
    case 'text':
    default:
      return [];
  }
}

export const trunkNumber = (precision, decimal, value) => {
  console.log(`multiplier: ${10 ** decimal}`);
  let output = parseFloat(value) * (10 ** decimal);
  console.log(`original: ${value}`);
  console.log(`inflated ${output}`);
  output = Math.round(output);
  console.log(`rounded: ${output}`);
  output = output / (10 ** decimal);
  console.log(`deflated: ${output}`);
  console.log(`substr(${output.toString().length})`);
  output = output.toString().substring(output.toString().length - ( precision + 1 ));
  console.log(`output: ${output}`);
  return output;
};

export const maskValues = (type, value) => {
  //console.log(`maskValue ${value}`);
  let output = parseFloat(value);
  const ttype = type.split('(')[0];
  let retVal = null;
  switch(ttype) {
    case 'year':
    case 'number':
    retVal = parseFloat(value);
    return !isNaN(retVal) ? retVal : '' ;
    case 'int':
      retVal = parseInt(value);
      return isNaN(retVal) ? '' : retVal;
    case 'money':
      if(!isNaN(output)) {
        retVal = Math.round(output * 100) / 100;
        retVal = trunkNumber(7,2,retVal);
        return isNaN(retVal) ? '' : retVal;
      }
      return '';
    case 'decimal':
      const params = type.substring(8, type.length -1).split(',');
      let precision = parseInt(params[0]) ? parseInt(params[0]) : 7;
      let decimal = parseInt(params[1]) ? parseInt(params[1]) : 2;
      retVal = trunkNumber(precision, decimal, output);
      return isNaN(retVal) ? '' : retVal;
    case 'percent':
      return isNaN(output) ? '' : output;
    case 'zip':
    console.log('process ZIP');
      output = String(value);
    console.log('string');
    console.log(output);
      output = output.replace(/[^0-9]+/g, '');
    console.log('numbers');
    console.log(output);
    if(output.length == 9 ) {
      output = String(output);
      output = `${output.substring(0, 5)}-${output.substring(5,9)}`;
    }
    return output;
    case 'text':
    default:
      return value;
  }
}

export const addCommas = (value) => {
  const sValue = parseFloat(value);
  if(isNaN(sValue)) {
    return value;
  }
  return sValue.toLocaleString("en-Us");
}
