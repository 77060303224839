const appreciationCustom = [
  {
    label: 'name',
    value: 'name',
    type: 'text',
  },
  {
    label: 'type',
    value: 'type',
    type: 'text',
  },
  {
    label: 'value',
    value: 'value',
    type: 'text',
  },
]
export default appreciationCustom;
