<template>
  <v-row>
    <v-col cols="1" v-if="iData.isDefault">
      <v-tooltip bottom color="blue darken-4">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" color="yellow darken-2">
            <v-icon>mdi-star</v-icon>
          </v-btn>
        </template>
        Default Image
      </v-tooltip>
    </v-col>
    <v-col cols="1" v-else>
      <v-tooltip bottom color="blue darken-4">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="$emit('setDefault', iData)" v-bind="attrs" v-on="on" color="yellow darken-2">
            <v-icon>mdi-star-outline</v-icon>
          </v-btn>
        </template>
        Set Default
      </v-tooltip>
    </v-col>
    <v-col cols="2"><v-img :src="iData.fileData" max-height="100" max-width="100"/></v-col>
    <v-col cols="6"><v-text-field v-model="iData.name" label="Name" @blur="changeFile"/></v-col>
    <v-col cols="2"><v-btn @click="viewFile" text color="blue darken-4"><v-icon>{{ data.flags &  8 ? 'mdi-download' : 'mdi-magnify' }}</v-icon></v-btn></v-col>
    <v-col cols="1"><v-btn icon @click="closeFile"><v-icon>mdi-close</v-icon></v-btn></v-col>
  </v-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'attachmentsView',
  props: {
    data: {
      type: Object,
      required: true,
    },
    itemKey: {
      type: [ Number, String ],
      default: null,
    },
  },
  setup(props, { emit }) {
    const iData = ref({...props.data});
    const changeFile = ()  => {
      console.log('file change');
      emit('changeFile', iData, props.itemKey);
    };
    const viewFile = () => {
      emit('viewFile', iData, props.itemKey);
    }
    const closeFile = () => {
      emit('closeFile', iData, props.itemKey);
    }

    watch(() => props.data, () => {
      iData.value = { ...props.data };
    },
    { 
      deep: true 
    });

    return {
      viewFile,
      closeFile,
      iData,
      changeFile,
    }
    
  }
});
</script>
<style lang="scss" scoped>

</style>
