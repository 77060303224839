import { getRule } from '@/utils/rules';
const financialSchema = [
  {
    label: 'PGI',
    value: 'PGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
  },
  {
    label: 'Vacancy',
    value: 'vacancy',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
  },
  {
    label: 'EGI',
    value: 'EGI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
  },
  {
    label: 'Expenditures',
    value: 'expenditure',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
  },
  {
    label: 'NOI',
    value: 'NOI',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
  },
  {
    label: 'OAR',
    value: 'OAR',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '%',
    suffix: '',
  },
  {
    label: 'OER',
    value: 'OER',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
  },
];
export default financialSchema;
