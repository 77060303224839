<template>
<div>
  <addCustomField 
    :dataSet="customFieldIface"
    :loading="appLoading"
    :errorStatus="customFieldError"
    @setError="$emit('setCustomFieldIfaceError', '')"
    @closeDialog="$emit('toggleCustomFieldIface', '')" 
    @addCustomField="$emit('addCustomField', ...arguments)"
    />
  <confirmDialog 
    :showDialog="duplicateData" 
    title="Duplicate Data" 
    :description="`Are you sure you would like to copy all data from ${SaleType ? 'Sale' : 'Lease'}`" 
    noText="Nevermind."
    yesText="Yes, I'm sure."
    @no='duplicateData = false'
    @yes="doDuplicateData"
    :yesFirst="true"
    :error="duplicateDataError"
    />
  <errorDialog 
    :showDialog="saveError" 
    title="Error" 
    :description="saveError" 
    yesText="Oh No!"
    @yes="saveError=''"
    />
   <v-form>
  <div style="width: 90vw; height: 90vh; margin: 5vh 5vw; background: #fff; border-radius: 25px; border: 12px solid #F1F3F5;background: #F1F3F5;">
    <v-row style="background:white; border-top-left-radius:25px; border-top-right-radius:25px;">
      <v-col cols="" class="add-data">
        Add Data <span style="color: #888; ">/</span> {{ category }} <span style="color: #888; ">/</span> {{ subCategory }}
      </v-col>
      <v-spacer/>
      <v-col cols="2">
        <v-tooltip bottom color="blue darken-4">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="" text dense @click="togglePropertyType" :color="SaleType ? 'green darken-4' : 'blue darken-4'" v-bind="attrs" v-on="on">
              <v-icon>{{ SaleType ? 'mdi-finance' : 'mdi-currency-usd' }}</v-icon>
              {{ SaleType ? 'Lease' : 'Sale' }}
            </v-btn>
          </template>
          Property is a {{ SaleType ? 'Lease' : 'Sale' }} 
        </v-tooltip>
      </v-col>
      <v-col cols="2">
        <v-tooltip bottom color="blue darken-4">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mb-6"
                   text
                   outlined
                   dense
                   light
                   @click="duplicateData = true"
                   width="150"
                   v-bind="attrs" 
                   v-on="on"
                   >
              Duplicate Data
            </v-btn>
          </template>
          Copy Data from {{ SaleType ? 'Sale' : 'Lease' }} 
        </v-tooltip>
      </v-col>
      <v-col cols="2">
        <v-btn 
          @click="saveAndExit" style="float:right"
          rounded
          dark
          color="blue darken-4"
          width="170"
          >
          <v-progress-circular indeterminate v-if="saving" size="25" class="mr-2"></v-progress-circular>
          <v-icon v-else class="mr-2">mdi-content-save</v-icon>

          Save & Exit
        </v-btn>
      </v-col>

      </v-row>
      <div style="background: #F1F3F5; border-bottom-left-radius: 25px; border-bottom-right-radius: 25px; color:black; height: calc(90vh - 90px); overflow-y:auto; overflow-x:hidden; margin-top:12px;">
        <v-row>
          <v-col>
            <v-row v-if="DataRecord">
              <v-col 
                v-for="(tSchema, li) in tableList" :key="li"
                :cols="'cols' in tSchema && tSchema.cols ? tSchema.cols : 4"
                >
                <component
                  :is="tSchema.type"
                  :schema="dataSchema[tSchema.name]" 
                  :errorStatus="errors[tSchema.name]"
                  :schemaName="tSchema.name"
                  :data="DataRecord[tSchema.name]"
                  :title="tSchema.title ? tSchema.title : tSchema.name" 
                  :subCategory="subCategory"
                  @update="updateRecord(tSchema.name, ...arguments)" 
                  @emitError="emitError(tSchema.name, ...arguments)"
                  @updateCustomField="updateCustomFieldDataset(tSchema.name, ...arguments)"
                  @addCustomField="doAddCustomField(tSchema.name)"
                  @dragItem="dragItem(tSchema.name, ...arguments)"
                  @deleteListRecord="deleteListRecord(tSchema.name, ...arguments)"
                  @addTableRow="addTableRow"
                  @setDefault="setDefault"
                  :loading="loading[tSchema.name]" 
                  />
              </v-col>
          </v-row>
          </v-col>
        </v-row>
      </div>
  </div>
  </v-form>
</div>
</template>

<script>
  import { 
    defineComponent,
    ref,
    computed,
    watch,
  } from 'vue';
  import bubbleView from '@/components/dataViews/bubbleView';
  import attachmentsView from '@/components/dataViews/attachmentsView';
  import tableView from '@/components/dataViews/tableView';
  import confirmDialog from '@/components/general/confirmDialog';
  import errorDialog from '@/components/general/errorDialog';
  import addCustomField from '@/components/addCustomField'
  import * as schemaData from '@/data';
  import { api } from  '@/services/api-service';
  import { maskValues, removeCommas, addCommas } from '@/utils/rules';
  import tableSchema from '@/data/tableSchema';

  export default defineComponent({
    name: 'addDataInterface',
    props: {
      category: {
        type: String,
        default: 'No Category',
      },
      subCategory: {
        type: String,
        default: 'No Sub Category',
      },
      SaleType: {
        type: Boolean,
        required: true,
      },
      DataRecord: {
        type: Object,
      },
      customFieldIface: {
        type: String,
        default: '',
      },
      customFieldError: {
        type: String,
        default: '',
      },
      appLoading: {
        type: Boolean,
        default: false,
      },
    },
    components: { bubbleView, tableView, attachmentsView, addCustomField, confirmDialog, errorDialog },
    setup(props, { emit }) {
      const saveError = ref(null);
      const tableList = computed(() => {
        return tableSchema.filter((i) => {
          const inSaleType = i.saleTypes.reduce((li, lj) => {
            if(props.SaleType && lj == 'Lease' || !props.SaleType && lj == 'Sale') {
              li = true;
            }
            return li;
          }, false);
          const inCat = i.subCategories.reduce((li, lj) => {
            if(props.subCategory === lj) {
              li = true;
            }
            return li;
          }, false);
          return inCat && inSaleType;
        });
      });
      console.log('tableList');
      console.log(tableList);

      const lastSaved = ref('Never');
      const saving = ref(false);

      const loading = ref({
        properties: false,
        attachments: false,
        apartmentFinancial: false,
        landTransactional: false,
        landSite: false,
        industrialSite: false,
        transactionalData: false,
        siteInfo: false,
        restaurantImprovments: false,
        retailImprovements: false,
        storageImprovements: false,
        improvements: false,
        leaseInformation: false,
        apartmentImprovements: false,
        plantings: false,
        unitMix: false,
        financial: false,
        remarks: false,
        Oar: false,
        appreciation: false,
        Irr: false,
        improvementItems: false,
      });

      const errors = ref({
        properties: '',
        attachments: '',
        apartmentFinancial: '',
        landTransactional: '',
        landSite: '',
        industrialSite: '',
        transactionalData: '',
        siteInfo: '',
        restaurantImprovments: '',
        retailImprovements: '',
        storageImprovements: '',
        improvements: '',
        leaseInformation: '',
        apartmentImprovements: '',
        plantings: '',
        unitMix: '',
        financial: '',
        remarks: '',
        Oar: '',
        appreciation: '',
        Irr: '',
        improvementItems: '',
      });

      const dataSchema = ref({
        irr: schemaData.irrSchema,
        oar: schemaData.oarSchema,
        appreciation: schemaData.appreciationSchema,
        apartmentFinancial: schemaData.apartmentFinancialSchema,
        attachments: schemaData.attachmentsSchema,
        financial: schemaData.financialSchema,
        improvements: schemaData.improvementsSchema,
        improvementItems: schemaData.improvementItemsSchema,
        leaseInformation: schemaData.leaseInformationSchema,
        plantings: schemaData.plantingsSchema,
        properties: schemaData.propertiesSchema,
        remarks: schemaData.remarksSchema,
        siteInfo: schemaData.siteInfoSchema,
        transactional: schemaData.transactionalSchema,
        unitMix: schemaData.unitMixSchema,
      });

      const duplicateData = ref(false);
      const duplicateDataError = ref('');
      const doDuplicateData = async () => {
        /*
       console.log('do duplicate data, implemented');
        duplicateDataError.value = 'Not Implemented';

        setTimeout(() => {
          duplicateData.value = false;
          duplicateDataError.value = '';
        },3000);
        //*/
        try {
          console.log('do duplicate data');
          duplicateDataError.value = '';
          const { id } = props.DataRecord;
          if(id) {
            console.log(`id: ${id}`);
            emit('openProperty', id, true)
            duplicateData.value = false;
          } else {
            throw new Error('No Record Open');
          }
        } catch(e) {
          console.log('Duplicate Data error');
          console.log(e);
          duplicateDataError.value = e.message ? e.message : 'Duplicate Data Error';
        }
      };

      const togglePropertyType = async () => {
        try {
          const propType = !props.DataRecord.propertyType
          await api.setRecord('records', 'propertyType', propType, props.DataRecord.id);
          emit('updatePropertyType', propType);

        } catch(e) {
          console.log('error updating property type');
          console.log(e);
        }
      }

      const saveRecord = async (dataSet, field, value, id, record, fromCalc) => {
        console.log(`saveRecord: ${value}`);
        loading.value[dataSet]=true;
        try {
          console.log('get schema');
          const schema = dataSchema.value[dataSet];
          if(schema) {
            console.log('got schema');
            console.log(schema);
            console.log(`save record ${id}`);
            console.log(`find field ${field}`);
            const fieldSpec = schema.reduce((i, j, k) => {
              if(j.value === field) {
                console.log(`found match at ${k}`);
                return j;
              }
              return i;
            }, null);
            console.log('fieldspec');
            console.log(fieldSpec);
            const { type } = fieldSpec;

            const { calc } = fieldSpec;
            console.log(`type: ${type}`);
            let iValue = value;
            let cValue = value;
            if(type) {
              const ptype = type.split('(')[0];
              console.log(`type ${ptype}`);
              switch(ptype) {
                case 'percent':
                case 'number':
                case 'decimal':
                case 'money':
                  iValue = maskValues(type, removeCommas(value));
                  iValue = iValue === '' ? null : iValue;
                  cValue = addCommas(iValue);
                  break;
                default:
                  console.log('no commas');
                  iValue = maskValues(type, value);
                  iValue = iValue === '' ? null : iValue;
                  cValue = iValue;
                  break;
              }
              iValue = iValue === '' ? null : iValue;

              await api.setRecord(dataSet, field, iValue, id, record);
              console.log(`update data ${field} => ${cValue}`);
              emit('updateData', dataSet, field, cValue, id, type);
              if(calc && !fromCalc) {
                console.log('do calc');
                await calc(iValue, id, record, saveRecord, props.DataRecord);
                console.log('calc finished');
              }

              console.log('save succeded');
            } else {
              throw new Error('Schema Type is missing');
            }
            loading.value[dataSet] = false;
          }
        } catch(e) {
          if (e.message === "Unauthenticated") {
            emit('authError', "Could not authenticate the user. Please Log in.")
          }
          else {
           console.log('error saving');
           console.log(e.name);
           console.log(e);
           errors.value[dataSet] += e.message + "\t";
          } 
          loading.value[dataSet] = false;
        }
      };

      const updateRecord = async (dataSet, field, value, id, record) => {
        console.log(`updaterecord ${id}`);
        console.log(dataSet);
        console.log(field);
        console.log(value);
        console.log(id);
        console.log(record);
        await saveRecord(dataSet, field, value, id, record);
        if(dataSet === 'properties') {
          switch(field) {//eslint-disable-line
            case 'address'://eslint-disable-line
            case 'city'://eslint-disable-line
            case 'state'://eslint-disable-line
            case 'county'://eslint-disable-line
            case 'zip'://eslint-disable-line
             console.log('geolocateAddress');
            emit('geoLocateAddress', 
                 (e) => {
                   errors.value[dataSet] += e.message;
                 },
                 async (lat, lng) => {
                   console.log(`done called ${lat}, ${lng}`);
                   await saveRecord('properties', 'lat', lat, id, record);
                   await saveRecord('properties', 'lng', lng, id, record);
                 },
                );
            break;
          }
        }
      };

      const emitError = (dataSet, error) => {
        errors.value[dataSet] = error;
      };

      const doAddCustomField = (dataSet) => {
        console.log(`Add Custom Field to ${dataSet}`);
        emit('toggleCustomFieldIface', dataSet);
      };

      const updateCustomFieldDataset = (dataSet, record) => {
        console.log('updateCustomFieldDataset');
        console.log(dataSet);
        console.log(record);
        loading.value[dataSet] = true;

        emit('updateCustomFieldDataset', dataSet, record, 
             () => { //done callback
               loading.value[dataSet] = false;
             },
             (err) => { //error callback
               errors.value[dataSet] = err;
             }
            );
      };

      const closeDataInterface = () => {
        saveError.value = '';
        emit('close', true);
      };

      const saveAndExit = async () => {
        console.log('save and exit');
        try {
          const { id } = props.DataRecord;
          if(!id) {
            throw new Error('No valid record exists.');
          }
          saving.value = true;
          // const response = await api.saveRecord({id:id})
          await api.saveRecord({id:id})
          saving.value=false;
          //saveError.value = response;
          emit('save', true);
        } catch(e) {
          if (e.message === "Unauthenticated") {
            emit('authError', "Could not authenticate the user. Please Log in.")
          }
          saving.value=false;
          saveError.value = e.message;
        }
      };

      const deleteListRecord = (dataSet, key) => {
        loading.value[dataSet] = true;
        console.log('deleteListRecord');
        console.log(dataSet);
        console.log(key);
        emit('deleteListRecord', dataSet, key,
            () => { //done callback
              loading.value[dataSet] = false;
            },
            (err) => { //error callback
              errors.value[dataSet] = err
            });
        
      };

      const dragItem = (schema, from, to) => {
        console.log(`dragAttachment: ${schema} : ${from} - ${to}`);
      };

      const addTableRow= async (compType, data) => {
        console.log('addTableRow');
        try {
          loading.value[compType] = true;
          console.log(`compType: ${compType}`);
          const { id } = props.DataRecord;
          console.log(`id ${id}`);
          const newRow = await api.addTableRow(id, compType, data);

          loading.value[compType] = false;
          console.log(newRow);
          emit('addTableRow', compType, newRow.data);
        } catch(e) {
          if (e.message === "Unauthenticated") {
            emit('authError', "Could not authenticate the user. Please Log in.")
          }
          errors.value[compType] = e.message;
          loading.value[compType] = false;
        }

      };
      watch(duplicateData,(newVal) => {
        if(!newVal) { duplicateDataError.value = ''; }
      });
      const setDefault = (data) => {
        console.log('setDefault');
        console.log(data);
        loading.value.attachments = true;
        emit('setDefault', data,
             () => { //done callback
               loading.value.attachments = false;
             },
             (err) => { //error callback
               loading.value.attachments = false;
               errors.value.attachments = err.message;
             }
            );
      };

      return {
        togglePropertyType,
        setDefault,
        addTableRow,
        deleteListRecord,
        dragItem,
        closeDataInterface,
        saveError,
        updateCustomFieldDataset,
        doAddCustomField,
        emitError,
        errors,
        loading,
        duplicateData,
        doDuplicateData,
        duplicateDataError,
        lastSaved,
        dataSchema,
        updateRecord,
        saving,
        saveAndExit,
        tableList,
      };
    },
  });
</script>
<style lang="scss" scoped>
  .last-saved {
    font-size: 10pt;
    color: #888;
  }
  .add-data {
    color: #154985;
    font-weight: 700;
    font-size: 14pt;
  }
</style>
<style>
  .heading {
    color: #154985;
    font-weight: 500;
    font-size: 18pt;
  }
</style>
