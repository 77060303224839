import { getRule } from '@/utils/rules';
const transactionalSchema = [
  {
    label: 'Buyer',
    value: 'buyer',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Seller',
    value: 'seller',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Recording Date',
    value: 'recordingDate',
    type: 'datepicker',
    subCategories: ['*'],
  },
  {
    label: 'Doc Number',
    value: 'docNumber',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Marketing Time',
    value: 'marketingTime',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Broker',
    value: 'broker',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Confirm Date',
    value: 'confirmDate',
    type: 'datepicker',
    subCategories: ['*'],
  },
  {
    label: 'Occupancy',
    value: 'occupancy',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
  },
  {
    label: 'Rights',
    value: 'rights',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Price/SF',
    value: 'priceSF',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '/SF',
  },
  {
    label: 'Price/Unit',
    value: 'priceUnit',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '/Unit',
  },
  {
    label: 'Sale Price',
    value: 'priceSale',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
  },
  {
    label: 'Price Adj',
    value: 'priceAdj',
    type: 'price',
    rules: getRule('price'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
  },
  {
    label: 'Land Value',
    value: 'landValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '',
  },
  {
    label: 'House Value',
    value: 'houseValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '',
  },
  {
    label: 'Dairy Improvements',
    value: 'dairyImprovements',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '',
  },
  {
    label: 'Price/Acre',
    value: 'priceAcre',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '/Acre',
  },
  {
    label: 'Price/Milking Cow',
    value: 'priceMilkingCow',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '/Milking Cow',
  },
  {
    label: 'Improvements/Cow',
    value: 'improvementsCow',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '$',
    suffix: '/Cow',
  },
];
export default transactionalSchema;
