import { fetcher } from './fetcher';

class API {
  login(username, password, rememberMe) {
    return fetcher.request({
      method: 'post',
      url: 'login',
      data: {
        username,
        password,
        rememberMe,
      },
    });
  }

  addData(category, subcategory, propertyType) {
    return fetcher.request(
      {
        method: 'post',
        url: 'records/addData',
        data: {
          category,
          subcategory,
          propertyType,
        },
      },
      {
        useAccessToken: true,
      },
    );
  }

  addTableRow(id, dataSet, data) {
    const dashed = dataSet.replace(/[A-Z]/g, m => `-${m.toLowerCase()}`);
    return fetcher.request(
      {
        method: 'post',
        url: `${dashed}/addTableRow`,
        data: {
          id: id,
          ...data,
        },
      },
      {
        useAccessToken: true,
      },
    );
  }

  deleteListRecord(id, dataSet) {
    const dashed = dataSet.replace(/[A-Z]/g, m => `-${m.toLowerCase()}`);
    return fetcher.request(
      {
        method: 'post',
        url: `${dashed}/deleteTableRow`,
        data: {
          id
        },
      },
      {
        useAccessToken: true,
      },
    );
  }

  setRecord(dataSet, field, value, id, recordId, cType) {
    const dashed = dataSet.replace(/[A-Z]/g, m => `-${m.toLowerCase()}`);
    return fetcher.request(
      {
        method: 'post',
        url: `${dashed}/setRecord/${field}`,
        data: {
          value: value,
          id: id,
          record:recordId,
          cType: cType
        },
      },
      {
        useAccessToken: true,
      },
    );
  }

  addCustomRecord(dataSet, field, fieldType, recordId) {
    const dashed = dataSet.replace(/[A-Z]/g, m => `-${m.toLowerCase()}`);
    return fetcher.request(
      {
        method: 'post',
        url: `${dashed}/addRecord/${field}`,
        data: {
          id: recordId,
          fieldType: fieldType,
        },
      },
      {
        useAccessToken: true,
      },
    );
  }

  getUsers() {
    return fetcher.request(
      {
        method: 'get',
        url: 'users',
      },
      {
        useAccessToken: true,
      }
    );
  }
  deleteUser(payload) {
    return fetcher.request(
      {
        method: 'post',
        url: 'users/delete',
        data: payload,
      },
      {
        useAccessToken: true,
      }
    );
  }
  editUser(payload) {
    return fetcher.request(
      {
        method: 'post',
        url: 'users/edit',
        data: payload,
      },
      {
        useAccessToken: true,
      }
    );
  }

  saveRecord(payload) {
    return fetcher.request(
      {
        method: 'post',
        url: 'records/saveRecord',
        data: payload,
      },
      {
        useAccessToken: true,
      }
    );
  }
  searchRecords() {
    return fetcher.request(
      {
        method: 'post',
        url: 'records/searchRecords',
      },
      {
        useAccessToken: true,
      },
    );
  }
  openData(id) {
    return fetcher.request(
      {
        method: 'get',
        url: `records/openRecord/${id}`,
      },
      {
        useAccessToken: true,
      }
    );
  }
  deleteProperty(id) {
    return fetcher.request(
      {
        method: 'get',
        url: `records/deleteRecord/${id}`,
      },
      {
        useAccessToken: true,
      }
    );
  }

  copyData(id, dupData) {
    return fetcher.request(
      {
        method: 'post',
        url: `records/copyRecord/${id}`,
        data: {
          duplicateData: dupData,
        }
      },
      {
        useAccessToken: true,
      }
    );
  }
  setDefaultAttachment(data) {
    return fetcher.request(
      {
        method: 'post',
        url: 'attachments/setDefault',
        data: data,
      },
      {
        useAccessToken: true,
      }
    );
  }
  getImage(imageId) {
    return fetcher.request(
      {
        method: 'get',
        url: `attachments/${imageId}`,
      },
      {
        useAccessToken: true,
      }
    );
  }
  getExcel(id) {
    return fetcher.request(
      {
        method: 'post',
        url: `records/xls`,
        data: {
          id
        },
      },
      {
        useAccessToken: true,
      }
    );
  }
  getDoc(id) {
    return fetcher.request(
      {
        method: 'post',
        url: `records/doc`,
        data: {
          id
        },
      },
      {
        useAccessToken: true,
      }
    );
  }
  storeMapImage(fileData, id) {
    return fetcher.request(
      {
        method: 'post',
        url: `attachments/storeMap`,
        data: {
          id,
          fileData,
        },
      },
      {
        useAccessToken: true,
      }
    );
  }
  getMapImage(location) {
    const api_key = process.env.VUE_APP_GOOGLE_MAPS_API_KEY 
    return fetch(`https://maps.googleapis.com/maps/api/staticmap?center=${location.lat},${location.lng}&zoom=12&size=1200x1200&key=${api_key}`)
    // Retrieve its body as ReadableStream
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
    // Create a new response out of the stream
      .then((stream) => new Response(stream))
    // Create an object URL for the response
      .then((response) => response.arrayBuffer())
      .then((buffer) => btoa(buffer))
      .catch((err) => console.error(err));
  }
  duplicateData(id) {
    return fetcher.request(
      {
        method: 'post',
        url: `records/duplicateData`,
        data: {
          id: id,
        },
      },
      {
       useAccessToken: true,
       }
    );
  }
  test() {
    return fetcher.request(
      {
        method: 'post',
        url: `records/test`,
        data: {
        },
      },
      {
        useAccessToken: true,
      }
    );
  }
  

}

export const api = new API();
