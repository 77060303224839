<template>
     <v-dialog
       v-model="openDialog"
       width="500"
       @keydown.enter="$emit('close')"
       @keydown.esc="$emit('close')"
       @click:outside="$emit('close')"
       >
       <v-card>
         <v-card-title light class="text-h5 white" style="color: #154985">
           {{ title }}
         </v-card-title>
         <v-card-text>
               <v-img :src="data"/>
               <v-row dense :class="{ errorSection: true, active: error !== '', inactive: error === '' }">
                 <v-col cols="12" style="color: red;" >
                   {{ error !== '' ? `Error: ${error}` : '' }}
                 </v-col>
               </v-row>
         </v-card-text>
         <v-divider></v-divider>

         <v-card-actions>
           <v-btn
             color="secondary"
             text
             @click="$emit('close');"
             >
               <v-icon>mdi-close</v-icon>
               Close
           </v-btn>
           <v-spacer></v-spacer>
           <v-btn
             color="primary"
             text
             @click="downloadFile"
             >
               <v-icon>mdi-download</v-icon>
               Download
           </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
</template>

<script>
  import { defineComponent, computed } from 'vue';
  export default defineComponent({
    name: 'viewDialog',
    props: {
      showDialog: {
        type: Boolean,
        value: false,
      },
      title: {
        type: String,
        default: 'unknown_file',
      },
      data: {
        type: String,
      },
      error: {
        type: String,
        default: '',
      }
    },
    setup(props) {
      console.log('setup viewDialog');
      const openDialog = computed(() => props.showDialog ? true : false);
      const downloadFile = () => { 
        const a = document.createElement('a')
        a.href = props.data;
        a.download=props.title;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
      return { openDialog, downloadFile };
    },
});
</script>
<style lang="scss" scoped>
@keyframes inactive {
  from {height: 0px; opacity: 100; }  
  to {height: 25px; opacity: 0; }
}
.errorSection {
  height: 0px;
  opacity: 0;
}
.inactive {
  height: 0px;
  opacity: 0;
  animation-name: inactive;
  animation-duration: 200ms;
}
.active {
  animation-name: inactiveError;
  animation-duration: 200ms;
  height: 25px;
  opacity: 100;
}
@keyframes inactiveError {
  from {height: 0px; opacity: 0; }  
  to {height: 25px; opacity: 100; }
}

</style>
