export class InternalServerError extends Error {
	constructor(message, response) {
		super(message);
		this.response = response;
	}
}

export class ClientRequestError extends Error {
	constructor(message, response) {
		super(message);
		this.response = response;
	}
}

export class UnauthenticatedError extends ClientRequestError {
	constructor(message, response) {
		super(message, response);
	}
}

export class ForbiddenError extends ClientRequestError {
	constructor(message, response) {
		super(message, response);
	}
}

export class NotFoundError extends ClientRequestError {
	constructor(message, response) {
		super(message, response);
	}
}