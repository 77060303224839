import { getRule } from '@/utils/rules';
const appreciationSchema = [
  {
    label: 'Cost New Acre',
    value: 'costNewAcre',
    type: 'money',
    suffix: '',
    prefix: '$',
    rules: getRule('money'),
    subCategories: ['*'],
  },
  {
    label: 'Weighted Age',
    value: 'weightedAge',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
];
export default appreciationSchema;
