import { getRule } from '@/utils/rules';
const genericInfoSchema = [
  {
    label: 'Test',
    value: 'image',
    type: 'image',
    rules: getRule('text'),
    subCategories: ['*'],
    cols: 12,
  },
  {
    label: 'Test',
    value: 'test',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
  },
{
    label: 'Test',
    value: 'test2',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
  },
];
export default genericInfoSchema;

