const apartmentFinancialCustomSchema = [
  {
    label: 'Name',
    value: 'name',
    type: 'text',
  },
  {
    label: 'Type',
    value: 'type',
    type: 'text',
  },
  {
    label: 'Value',
    value: 'value',
    type: 'text',
  },
]
export default apartmentFinancialCustomSchema;
