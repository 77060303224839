<template>
  <v-container style="height: calc(100vh - 200px); overflow:auto;" @dragover.prevent>
    <transition-group name="fadeLeft" tag="div" >
      <v-row v-for="(item, li) in selectedItems" :key="item">
        <v-col md="12">
          <v-row :class="{ dropzone: dropHere === li }" dense>
            <v-col 
              @drop="onDrop(li)"
              @dragover.prevent
              @dragenter="dragEnter($event, li)"
              @dragleave="dragLeave()"
              >
           </v-col>
          </v-row>
        </v-col>
        <v-col md="12" @dragover.prevent>
          <v-row>
            <v-col md="1" align-self="center">{{ li + 1 }}.</v-col>
              <v-col md="11" >
                <v-card class="m-5 drag" draggable @dragstart="startDrag($event, li)"  transition="scale-transition">
                  <v-row>
                    <v-col md="4" align-self="center">
                      <thumbNail :imageId="getItem(item).imageId"/>
                    </v-col>
                    <v-col md="8">
                      <v-row dense>
                        <v-col md="10" color="black" style="font-weight:800;">
                          {{ getItem(item).address }}
                        </v-col>
                        <v-spacer/>
                        <v-col md="1">
                          <v-btn class="mr-3"
                                 icon
                                 dense
                                 @click="removeItem(item)"
                                 color="red darken-4"
                                 style="float:right;"
                                 >
                            <v-icon class="">mdi-close</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row  dense>
                        <v-col md="5">
                          {{ getItem(item).saleDate }}
                        </v-col>
                        <v-col md="5">
                          PPA ${{ getItem(item).salePrice }}
                        </v-col>
                      </v-row>
                      <v-row  dense>
                        <v-col md="5">
                          {{ getItem(item).waterDistrict }}
                        </v-col>
                        <v-col md="5">
                          {{ getItem(item).acreage }} Acres
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
          </v-row>
        </v-col>
      </v-row>
    </transition-group>
      <v-row>
        <v-col md="12">
          <v-row :class="{ dropzone: dropHere === selectedItems.length }" dense>
            <v-col 
              @drop="onDrop(selectedItems.length)"
              @dragover.prevent
              @dragenter="dragEnter(selectedItems.length)"
              @dragleave="dragLeave()"
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
</v-container>

</template>

<script>
import { defineComponent, ref } from 'vue';
import { api } from '@/services/api-service';
import defaultImage from '@/assets/default-product.jpg';
import thumbNail from '@/components/general/thumbNail.vue';

export default defineComponent({
	name: 'selectedComparables',
  props: {
    selectedItems: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    thumbNail,
  },
  setup(props, { emit }) {
    const selectedRows = ref([]);
    console.log('table');
    console.log(props);
    const image = ref();
    const search = ref('');
    const filterItems = (value, search, item) => {
      console.log(props.filter.minSale);
      if(props.filter.minSale && props.filter.minSale < item.salePrice) {
        console.log('return true');
        return 1;
      }
      /*
      console.log('filterItems');
      console.log(value);
      console.log(search);
      console.log(item);
      //*/
      return false;
    }
    const removeItem = (remove) => {
      console.log('remove item');
      console.log(remove);
      const item = props.items.find(i => i.id === remove);
      emit('updateSelectedItem', item, false);
      dropHere.value = null;
    };
    const getItem = (itemID) => {
      // console.log(`getItem ${itemID}`);
      const item = props.items.find(i => i.id === itemID);
      return item;
    };

    const startDrag = (evt, li) => {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      isDragging = li;
    }

    const onDrop = (li) => {
      if(isDragging !== li && isDragging + 1 != li) {
        if(isDragging < li) {
          emit('dragComparable', isDragging, li ? li - 1 : 0);
        } else {
          emit('dragComparable', isDragging, li);
        }
      } else {
        console.log('skip move');
      }
      dropHere.value = null;
    }

    const dragEnter = (evt, li) => {
      if(isDragging !== li && isDragging + 1 != li) {
        dropHere.value = li;
      } 
    };

    const dragLeave = () => {
      dropHere.value = null;
    };

    const dropHere = ref(null);

    let isDragging = null;
    const getImage = (imageId) => {
      image.value = null;
      console.log('get image');
      api.getImage(imageId)
      .then((imageRecord) => {
        console.log('done');
        console.log(imageRecord);
        const { data } = imageRecord;
        if(data) {
          console.log('got data');
          console.log(data);
          const { fileData } = data;
          if(fileData) {
            console.log('set actual image');
            image.value = fileData;
            return;
          }
        }
        image.value = defaultImage;
      })
      .catch((err) => {
        console.log('error');
        console.log(err);
        image.value = defaultImage;
      });
    }

    return {
      getImage,
      image,
      dragEnter,
      dragLeave,
      startDrag,
      onDrop,
      dropHere,
      getItem,
      removeItem,
      filterItems,
      selectedRows,
      search,
    };
  }
});
</script>

<style lang="scss" scoped>
  .drag {
    cursor: grab;
  }
  .dropzone {
    background: rgba(15, 182, 248, 0.267);
    border-radius: 3px;
    height: 22px;
    transition: all .3s ease;
  }

  .list-enter-active,
  .list-leave-active {
  transition: all 0.5s ease;
  }
  .list-enter-from,
  .list-leave-to {
  opacity: 0;
  transform: scale();
  }
</style>

