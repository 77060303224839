import { getRule, addCommas } from '@/utils/rules';

const SQUARE_FT_PER_ACRE = 43560
const siteInfoSchema = [
  {
    label: 'Site Area Acres',
    value: 'siteAreaAcres',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'test',
    ],
    prefix: '',
    suffix: 'Acres',
    calc: async (value, id, record, saveRecord) => {
      await saveRecord('siteInfo', 'siteAreaSqft', addCommas(value * SQUARE_FT_PER_ACRE), id, record, true)
    }
  },
  {
    label: 'Site Area SF',
    value: 'siteAreaSqft',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'test',
    ],
    prefix: '',
    suffix: 'SF',
    calc: async(value, id, record, saveRecord) => {
      await saveRecord('siteInfo', 'siteAreaAcres', addCommas(value / SQUARE_FT_PER_ACRE), id, record, true)
    }
  },
  {
    label: 'Total Acres',
    value: 'totalAcres',
    type: 'number',
    rules: getRule('number'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: 'Acres',
  },
  {
    label: 'GSA',
    value: 'GSA',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Planted Acres',
    value: 'plantedAcres',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: 'Acres',
  },
  {
    label: 'Pumping Restrictions',
    value: 'pumpingRestrictions',
    type: 'checkbox',
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
  },
  {
    label: 'Land Value Per Acre',
    value: 'landValuePerAcre',
    type: 'money',
    rules: getRule('money'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '$',
    suffix: '/Acre',
  },

  {
    label: 'Site Coverage Ratio',
    value: 'siteCoverageRatio',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'test',
    ],
    prefix: '',
    suffix: '%',
  },
  {
    label: 'Suprlus Land',
    value: 'suprlusLand',
    type: 'checkbox',
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'test',
    ],
  },
  {
    label: 'Site Allocation',
    value: 'siteAllocation',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Industrial',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Off Sites',
    value: 'offSites',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'On Sites',
    value: 'onSites',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Shape',
    value: 'shape',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Topography',
    value: 'topography',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Land',
      'test',
    ],
    prefix: '',
    suffix: '',
  },


  {
    label: 'Dairy Site',
    value: 'dairySite',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Percent Site Used',
    value: 'percentSiteUsed',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '%',
  },
  {
    label: 'Farm Land',
    value: 'farmLand',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Actual Milking',
    value: 'actualMilking',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Cow/Dairy Site Acre',
    value: 'cowDairySiteAc',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: 'Cows/DSA',
  },
  {
    label: 'Soils',
    value: 'soils',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Ground Water',
    value: 'groundWater',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Surface Water',
    value: 'surfaceWater',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Permit',
    value: 'permit',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Dairy',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Use',
    value: 'use',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Irrigation System',
    value: 'irrigationSystem',
    type: 'checkbox',
    subCategories: [
      'Permanent Plantings',
      'test',
    ],
  },
  {
    label: 'Utilities',
    value: 'utilities',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Zoning',
    value: 'zoning',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Apartment',
      'Land',
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'Self Storage',
      'Dairy',
      'Open Ground',
      'Permanent Plantings',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
];

export default siteInfoSchema
