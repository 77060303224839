class AuthService {
	constructor() {
		this.rememberMe = false;
		if (localStorage.getItem('user')) {
			this.rememberMe = true;
		}
	}

	storeCredentials(obj) {
    console.log(obj);
    const { data, rememberMe } = obj;
    let { user, accessToken, refreshToken } = data;
		if (user) {
			user = JSON.stringify(user);
		}

		this.clearCredentials();

		if (rememberMe) {
			this.rememberMe = true;
			localStorage.setItem('user', user);
			localStorage.setItem('accessToken', accessToken);
			localStorage.setItem('refreshToken', refreshToken);
		} else {
      console.log(user);
			sessionStorage.setItem('user', user);
			sessionStorage.setItem('accessToken', accessToken);
			sessionStorage.setItem('refreshToken', refreshToken);
		}
	}

	clearCredentials() {
		const keys = ['user', 'accessToken', 'refreshToken'];
		for (const key of keys) {
			localStorage.removeItem(key);
			sessionStorage.removeItem(key);
		}
	}

	get user() {
		try {
      console.log('remember me');
      console.log(this.rememberMe);
      console.log('done');
			const user = this.rememberMe
				? JSON.parse(localStorage.getItem('user'))
				: JSON.parse(sessionStorage.getItem('user'));
			return user;
		} catch (err) {
      console.log(err);
			console.log('????');
			return null;
		}
	}

	get accessToken() {
		return this.rememberMe
			? localStorage.getItem('accessToken')
			: sessionStorage.getItem('accessToken');
	}

	set accessToken(tok) {
		this.rememberMe
			? localStorage.setItem('accessToken', tok)
			: sessionStorage.setItem('accessToken', tok);
	}

	get refreshToken() {
		return this.rememberMe
			? localStorage.getItem('refreshToken')
			: sessionStorage.getItem('refreshToken');
	}

	set refreshToken(tok) {
		this.rememberMe
			? localStorage.setItem('refreshToken', tok)
			: sessionStorage.setItem('refreshToken', tok);
	}


}

export const authService = new AuthService();
