import { getRule } from '@/utils/rules';
const improvementsSchema = [
  {
    label: 'Gross Building Area',
    value: 'grossBuildingArea',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Net Rentable Area',
    value: 'netRentableArea',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Year Built',
    value: 'yearBuilt',
    type: 'year',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Percent Finished',
    value: 'percentFinished',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Retail',
      'Shopping',
      'test',
    ],
    prefix: '',
    suffix: '%',
  },
  {
    label: 'Percent Office',
    value: 'percentOffice',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Medical',
      'Office',
      'Restaurant',
      'Industrial',
      'test',
    ],
    prefix: '',
    suffix: '%',
  },
  {
    label: 'Num Units',
    value: 'numUnits',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Num Stories',
    value: 'numStories',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Sprinklers',
    value: 'sprinklers',
    type: 'checkbox',
    subCategories: ['*'],
  },
  {
    label: 'Drive Thru',
    value: 'driveThru',
    type: 'checkbox',
    subCategories: [
      'Restaurant',
      'test',
    ],
  },
  {
    label: 'Ameninities',
    value: 'ameninities',
    type: 'textarea',
    rules: getRule('text'),
    subCategories: [
      'Apartment',
      'test',
    ],
  },
  {
    label: 'Property Features',
    value: 'propertyFeatures',
    type: 'textarea',
    rules: getRule('text'),
    subCategories: [
      'Apartment',
      'test',
    ],
  },
  {
    label: 'Parking Stalls',
    value: 'parkingStalls',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Medical',
      'Office',
      'Restaurant',
      'Retail',
      'Industrial',
      'Shopping',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Parking Ratio',
    value: 'parkingRatio',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: [
      'Medical',
      'Office',
      'Industrial',
      'test',
    ],
    prefix: '',
    suffix: '%',
  },
  {
    label: 'Building Class',
    value: 'buildingClass',
    type: 'text',
    rules: getRule('text'),
    subCategories: [
      'Self Storage',
      'test',
    ],
    prefix: '',
    suffix: '',
  },
];
export default improvementsSchema;
