<template>
  <v-img v-if="image" class="thumbnail" :src="image" contain/>
  <v-img v-else class="thumbnail" :src="defaultImage" >
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="blue darken-4"
      style="margin-left: calc(50% - (25px / 2)); margin-top: calc(50% - (25px /2));"
      />
  </v-img>
</template>

<script>
import { defineComponent, ref, watchEffect } from 'vue';
import { api } from '@/services/api-service';
import defaultImage from "@/assets/default-product.jpg";
  
export default defineComponent({
  name: 'thumbNail',
  props: {
    imageId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const image = ref();
    const loading = ref(true);
    console.log('setup thumbNail');
    console.log(props);
    const getImage = (imageId) => {
      image.value = null;
      console.log('get image');
      api.getImage(imageId)
      .then((imageRecord) => {
        console.log('done');
        console.log(imageRecord);
        const { data } = imageRecord;
        if(data) {
          console.log('got data');
          console.log(data);
          const { fileData } = data;
          if(fileData) {
            console.log('set actual image');
            image.value = fileData;
            return;
          }
        }
        image.value = defaultImage;
        loading.value = false;
      })
      .catch((err) => {
        console.log('error');
        console.log(err);
        image.value = defaultImage;
        loading.value = false;
      });
    };

    watchEffect(() => {
      console.log('change thumbnail');
      console.log(props);
      if(props.imageId) {
        getImage(props.imageId);
      } else {
        loading.value = false;
      }
    });

    return {
      loading,
      image,
      defaultImage,
    }
  },
});
</script>
<style lang="scss" scoped>
.thumbnail {
  border-radius: 8px;
  margin-left: 15px;
  width: 140px;
  height: 140px;
}
</style>
