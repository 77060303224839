<template>
  <v-container>
  <v-dialog
    v-model="openDialog"
    width="800"
    @keydown.esc="$emit('close')"
    @click:outside="$emit('close')"
    >
    <v-card>
      <v-card-title light class="text-h5 white" style="color: #154985">
        User Administration
      </v-card-title>
      <v-card-text>
        
        <!-- From Table View -->
        <v-row dense>
          <v-col cols="12" dense style="overflow:auto;">
            <v-progress-linear absolute :active="loading" indeterminate color="blue darken-4" value="100"></v-progress-linear>
            <div v-if="!loading" style="width: 100%; height: 2px; background: #496688; margin-top: 1px 0 1px;"></div>
          </v-col>
        </v-row>
        <v-row dense :class="{ errorSection: true, active: errorStatus !== '', inactive: errorStatus === '' }">
          <v-col cols="10" class="pl-4 pt-2" style="color: red;" >
            {{ errorStatus !== '' ? `Error: ${errorStatus}` : '' }}
          </v-col>
          <v-col cols="1">
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-show="errorStatus !== ''" icon @click="errorStatus = ''" color="red darken-1" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              Close Error
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" dense>
            <v-data-table
              :headers="headers"
              :items="users"
              multi-sort
              :hide-default-footer="true"
              >
              <template
                v-slot:[`item.id`]="{ item }">
                <v-tooltip bottom color="blue darken-4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      icon 
                      @click="modifyUser(item)"
                      v-bind="attrs" 
                      v-on="on"
                      color="blue darken-4"
                      >
                      <v-icon>
                        mdi-square-edit-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  Edit
                </v-tooltip>
                <v-tooltip bottom color="orange darken-4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      icon 
                      @click="changePassword(item)"
                      v-bind="attrs"
                      color="orange darken-4"
                      v-on="on"
                      >
                      <v-icon>
                        mdi-lock-alert
                      </v-icon>
                    </v-btn>
                  </template>
                  Reset Password
                </v-tooltip>
                <v-tooltip bottom color="red darken-4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      icon 
                      @click="deleteItem(item)" 
                      color="red darken-4"
                      v-bind="attrs"
                      v-on="on"
                      >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  Delete
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- // From Table View -->
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          color="secondary"
          text
          @click="$emit('close')"
          >
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="createNewUser"
          >
          New User
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-dialog
    v-model="showPasswordDialog"
    width="800"
    >
    <v-card>
      <v-card-title light class="text-h5 white" style="color: #154985">
        Change Password
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" dense style="overflow:auto;">
            <v-progress-linear absolute :active="doingDelete" indeterminate color="blue darken-4" value="100"></v-progress-linear>
            <div v-if="!loading" style="width: 100%; height: 2px; background: #496688; margin-top: 1px 0 1px;"></div>
          </v-col>
        </v-row>
        <v-row dense :class="{ errorSection: true, active: errorStatus !== '', inactive: errorStatus === '' }">
          <v-col cols="10" class="pl-4 pt-2" style="color: red;" >
            {{ errorStatus !== '' ? `Error: ${errorStatus}` : '' }}
          </v-col>
          <v-col cols="1">
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-show="errorStatus !== ''" icon @click="errorStatus = ''" color="red darken-1" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              Close Error
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field type="password" v-model="newPassword" label="New Password"/>
          </v-col>
          <v-col cols="12">
            <v-text-field type="password" v-model="confirmPassword" label="Confirm Password"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="secondary"
          text
          @click="closePasswordDialog"
          >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="doChangePassword"
          >
          Change Password
        </v-btn>
      </v-card-actions>
    </v-card>             
  </v-dialog>


  <v-dialog
    v-model="deleteUserDialog"
    width="800"
    >
    <v-card>
      <v-card-title light class="text-h5 white" style="color: #154985">
        Delete User
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" dense style="overflow:auto;">
            <v-progress-linear absolute :active="doingDelete" indeterminate color="blue darken-4" value="100"></v-progress-linear>
            <div v-if="!loading" style="width: 100%; height: 2px; background: #496688; margin-top: 1px 0 1px;"></div>
          </v-col>
        </v-row>
        <v-row dense :class="{ errorSection: true, active: errorStatus !== '', inactive: errorStatus === '' }">
          <v-col cols="10" class="pl-4 pt-2" style="color: red;" >
            {{ errorStatus !== '' ? `Error: ${errorStatus}` : '' }}
          </v-col>
          <v-col cols="1">
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-show="errorStatus !== ''" icon @click="errorStatus = ''" color="red darken-1" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              Close Error
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" dense>
            Are you sure you want to delete the user  "{{ itemToDelete ? itemToDelete.firstName : '' }} {{ itemToDelete ? itemToDelete.lastName : '' }}":  {{ itemToDelete ? itemToDelete.email : '' }}
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="secondary"
          text
          @click="errorStatus = ''; deleteUserDialog = false"
          >
          No
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="doDeleteUser"
          >
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>             
  </v-dialog>


  <v-dialog
    v-model="modifyUserDialog"
    width="800"
    >
    <v-card>
      <v-card-title light class="text-h5 white" style="color: #154985">
        Edit User
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" dense style="overflow:auto;">
            <v-progress-linear absolute :active="doingDelete" indeterminate color="blue darken-4" value="100"></v-progress-linear>
            <div v-if="!loading" style="width: 100%; height: 2px; background: #496688; margin-top: 1px 0 1px;"></div>
          </v-col>
        </v-row>
        <v-row dense :class="{ errorSection: true, active: errorStatus !== '', inactive: errorStatus === '' }">
          <v-col cols="10" class="pl-4 pt-2" style="color: red;" >
            {{ errorStatus !== '' ? `Error: ${errorStatus}` : '' }}
          </v-col>
          <v-col cols="1">
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-show="errorStatus !== ''" icon @click="errorStatus = ''" color="red darken-1" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              Close Error
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="modifyUserItem.username" label="Username"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="modifyUserItem.firstName" label="First Name"/>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="modifyUserItem.lastName" label="Last Name"/>
          </v-col>  
          <v-col cols="12">
            <v-text-field v-model="modifyUserItem.email" label="Email Address"/>
          </v-col>  
          <v-col cols="12">
            <v-text-field v-model="modifyUserItem.phone" label="Phone Number"/>
          </v-col>  
          <v-col cols="12">
            <v-text-field type="password" v-model="newPassword" label="New Password"/>
          </v-col>
          <v-col cols="12">
            <v-text-field type="password" v-model="confirmPassword" label="Confirm Password"/>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="modifyUserItem.admin" label="Admin"/>
          </v-col>

        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="secondary"
          text
          @click="errorStatus = ''; modifyUserDialog = false"
          >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="doModifyUser"
          >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>             
  </v-dialog>
  </v-container>


</template>

<script>
  import { defineComponent, computed, ref, onMounted } from 'vue';
  import { api } from '@/services/api-service';

  export default defineComponent({
    name: 'userAdminDialog',
    props: {
      showDialog: {
        type: Boolean,
        value: false,
      },
      userAdmin: {
        type: Boolean,
        value: false,
      },
    },
    setup(props, { emit }) {
      const openDialog = computed(() => props.showDialog && props.userAdmin ? true : false);
      const showPasswordDialog = ref(!props.userAdmin);
      const errorStatus = ref('');
      const users = ref();
      const loading = ref(false);
      const headers = [
        {
          text: 'Actions',
          sortable: true,
          value: 'id',
        },
        {
          text: 'First',
          sortable: true,
          value: 'firstName',
        },
        {
          text: 'Last',
          sortable: true,
          value: 'lastName',
        },
        {
          text: 'Email',
          sortable: true,
          value: 'email',
        },
        {
          text: 'Phone',
          sortable: true,
          value: 'phone',
        },
      ];
      onMounted(() => {
        if(props.userAdmin) {
          refreshUsers();
        }
        
      });
      const refreshUsers = async () => {
        try {
          console.log('userAdminDialoag has been mounted!');
          loading.value = true;
          const userData = await api.getUsers();
          console.log('done');
          console.log(userData.data);
          users.value = userData.data;
          loading.value=false
        } catch (error) {
          loading.value=false;
          console.log('error getting users');
          console.log(error);
          errorStatus.value=error.message;
        }
      }
      /* Change Passowrd stuff */
      const doChangePassword = () => {
        console.log('do change password');
        if(newPassword.value === confirmPassword.value) {
          const payload = { 
            ...userToChange.value,
            password: newPassword.value,
          }
          api.editUser(payload)
          .then(() => {
            newPassword.value = null;
            confirmPassword.value = null;
            errorStatus.value = '';
            showPasswordDialog.value = false;
            if(!props.isAdmin) {
              emit('close');
            }
          })
          .catch((e) => {
            errorStatus.value=e.message;
          });
        } else {
          errorStatus.value='Passwords do not match';
        }
      }
      
      const userToChange = ref();
      const changePassword = (data) => {
        console.log('change password');
        console.log(data);
        userToChange.value = data;
        showPasswordDialog.value = true;
      }
      const newPassword = ref();
      const confirmPassword = ref();

      /* Delete User Stuff */
      const doingDelete = ref(false);
      const doDeleteUser = async () => {
        try {
          await api.deleteUser(itemToDelete.value);
          itemToDelete.value = null;
          deleteUserDialog.value = false;
          refreshUsers();
        } catch(error) {
          errorStatus.value = error.message;
        }
      }
      const itemToDelete = ref();
      const deleteUserDialog = ref(false);
      const deleteItem = (item) => {
        console.log('delete item');
        console.log(item);
        itemToDelete.value = item;
        deleteUserDialog.value = true;
      }


      /* Edit User Stuff */
      const modifyUser = (item) => {
        console.log('modify user');
        modifyUserItem.value = item;
        modifyUserDialog.value = true;
      };
      const modifyUserDialog = ref(false);
      const newUserItem = {
        id: null,
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        admin: false,
      }
      const modifyUserItem = ref(newUserItem);
      const doModifyUser = async () => {
        console.log('do modify user');
        if(newPassword.value !== confirmPassword.value) {
          errorStatus.value = 'Passwords do not match'
          return;
        }
        console.log(modifyUserItem.value);
        if(!modifyUserItem.value.id && !newPassword.value) {
          errorStatus.value = `Password is required`
          return;
        }
        try {
          await api.editUser({...modifyUserItem.value, newPassword: newPassword.value, confirmPassword: confirmPassword.value});
          refreshUsers();
          modifyUserItem.value = newUserItem;
          modifyUserDialog.value = false;
        } catch(error) {
          errorStatus.value = error.message;
        }

      }
      /* New User Stuff */
      const createNewUser = () => {
        console.log('new user');
        modifyUserItem.value = newUserItem;
        modifyUserDialog.value = true;
      }      

      const closePasswordDialog = () => {
        errorStatus.value = ''; 
        showPasswordDialog.value = false;
        if(props.isAdmin) {
          emit('close');
        }
      }

      return {
        closePasswordDialog,
        createNewUser,
        modifyUserDialog,
        modifyUser,
        modifyUserItem,
        doModifyUser,
        doDeleteUser,
        itemToDelete,
        doingDelete,
        deleteUserDialog,
        deleteItem,
        newPassword,
        confirmPassword,
        userToChange,
        doChangePassword,
        showPasswordDialog,
        changePassword,
        users,
        headers,
        loading,
        openDialog,
        errorStatus,
      };
    },
  });
</script>
<style lang="scss" scoped>
@keyframes inactive {
  from {height: 0px; opacity: 100; }  
  to {height: 45px; opacity: 0; }
}
.errorSection {
  height: 0px;
  opacity: 0;
}
.inactive {
  height: 0px;
  opacity: 0;
  animation-name: inactive;
  animation-duration: 200ms;
}
.active {
  animation-name: inactiveError;
  animation-duration: 200ms;
  height: 45px;
  opacity: 100;
}
@keyframes inactiveError {
  from {height: 0px; opacity: 0; }  
  to {height: 45px; opacity: 100; }
}

</style>
