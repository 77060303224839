import { getRule } from '@/utils/rules';
const improvementItemsSchema = [
  {
    label: 'Type',
    value: 'type',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Age',
    value: 'age',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Size',
    value: 'size',
    type: 'number',
    rules: getRule('number'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Cost New',
    value: 'costNew',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
  },
  {
    label: 'Continued Value',
    value: 'continuedValue',
    type: 'money',
    rules: getRule('money'),
    subCategories: ['*'],
    prefix: '$',
    suffix: '',
  },
  {
    label: 'Value/SF',
    value: 'valueSF',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '/SF',
  },
  {
    label: 'Depreciation Percent',
    value: 'depreciationPercent',
    type: 'percent',
    rules: getRule('percent'),
    subCategories: ['*'],
    prefix: '',
    suffix: '%',
  },
];
export default improvementItemsSchema;
