import { getRule } from '@/utils/rules';
const propertiesSchema = [
  {
    label: 'Comp Title',
    value: 'compTitle',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Address',
    value: 'address',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'City',
    value: 'city',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'County',
    value: 'county',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Zip',
    value: 'zip',
    type: 'zip',
    rules: getRule('zip'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Apn',
    value: 'apn',
    type: 'text',
    rules: getRule('text'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Latitude',
    value: 'lat',
    type: 'decimal(22,8)',
    rules: getRule('decimal'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
  {
    label: 'Longitude',
    value: 'lng',
    type: 'decimal(22,8)',
    rules: getRule('decimal'),
    subCategories: ['*'],
    prefix: '',
    suffix: '',
  },
];
export default propertiesSchema;
