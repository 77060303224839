<template>
<div>
    <confirmDialog 
      :showDialog="areYouSure !== null" 
      title="Delete File" 
      :description="`Are you sure you want to delete ${fileToDelete}?`" 
      noText="Nevermind."
      yesText="Yes, I'm sure."
      @no='areYouSure = null' 
      @yes="doDeleteFile(areYouSure)"
      :yesFirst="false"
      />
    <viewDialog 
      :showDialog="viewImage !== null" 
      :title="viewImageName" 
      :data="viewImage" 
      @close="viewImage = null"
      />

  <v-card class="ma-6">
    <v-row class="mb-5 topRow" dense>
      <v-col cols="10" class="pl-4 heading" dense>
        {{ title }}
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" dense style="margin-top: -20px;">
        <v-progress-linear absolute :active="loading" indeterminate color="blue darken-4" value="100"></v-progress-linear>
        <div v-if="!loading" style="width: 100%; height: 2px; background: #496688; margin-top: 1px 0 1px;"></div>
      </v-col>
    </v-row>
    <v-row dense :class="{ errorSection: true, active: errorStatus !== '', inactive: errorStatus === '' }" style="overflow-x:auto;">
      <v-col v-if="errorStatus != ''" cols="10" class="pl-4 pt-2" style="color: red;" >
        {{ `Error: ${errorStatus}` }}
      </v-col>
      <v-col cols="1">
        <v-tooltip bottom color="red">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-show="errorStatus != ''" icon @click="$emit('emitError', '')" color="red darken-1" v-bind="attrs" v-on="on">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          Close Error
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pl-6 pr-6">
        <v-row>
          <v-col cols="12" class="dropSection pt-6 pb-6" @click="triggerFileUploadedWithButton" @drop="uploadFile"  @dragover.prevent>
            Drag 'n' drop some files here, or click to select files
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-for="(item, li) in iData" :key="li">
      <v-col cols="12"  class="pl-6 pr-6">
        <imageInput style="cursor: move; cursor: grab;" draggable @dragstart="startDrag($event,li)" :data="item" :itemKey="li" @changeFile="changeFile" @viewFile="viewFile" @closeFile="deleteFile" @setDefault="$emit('setDefault', ...arguments)"/>
      </v-col>
      <v-col cols="12" dense
        @drop="onDrop(li)"
        @dragover.prevent
        @dragenter="dragEnter($event, li)"
        @dragleave="dragLeave()"
      >
      </v-col>
    </v-row>
  </v-card>
  <div class="hiddenButton">
    <form ref="chooseFile">
      <input ref="hiddenButton" type="file" accept="image/jpeg, image/png"  @change="fileUploadedWithButton" />
    </form>
  </div>
</div>
</template>
<script>
import { defineComponent, watch, ref } from 'vue';
import imageInput from '@/components/fieldTypes/imageInput';
import confirmDialog from '@/components/general/confirmDialog';
import viewDialog from '@/components/general/viewDialog';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const IS_THUMBNAIL = 1;

export default defineComponent({
  name: 'attachmentsView',
  components: { imageInput, confirmDialog, viewDialog },
  props: {
    data: {
      type: Array,
      required: true,
    },
    schema: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'Title Goes Here',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    subCategory: {
      type: String,
      default: '',
    },
    errorStatus: {
      type: String,
      default: '',
    },
    schemaName: {
      type: String,
      required: true,
    },
  },
  
 setup(props, { emit }) {
   const chooseFile = ref();
   const hiddenButton = ref();
   let isDragging = false;
   const areYouSure = ref(null);
   const fileToDelete = ref('Unknown')
   const viewImage = ref(null);
   const viewImageName = ref('Unknown');
   const iData = ref(props.data.filter((i) => {
     if(!(i.flags & IS_THUMBNAIL)) {
       return true;
     }
   }));
   
   const handleNewFile = (file) => {
     // const data = iData.value;
     emit('addTableRow', props.schemaName, file);
     //data.push(file);
   }
   
   const changeFile = (file, key) => {
     console.log('changeFile');
     console.log(file);
     console.log(key);
     const data = file.value;
     iData.value[key] = data;
   }
    
   const viewFile = (file, key) => {
     console.log('viewFile');
     console.log(file);
     console.log(key);
     const image = iData.value[key];
     if(image) {
       viewImage.value = image.fileData;
       viewImageName.value = image.name;
     }
   };
   
   const deleteFile = (file) => {
     console.log('delete');
     const { value } = file;
     if(value) {
       const { id } = value;
       const { name } = value;
       if(id) {
         areYouSure.value = id;
         fileToDelete.value = name;         
       }
     }
   };
   
   const doDeleteFile = (key) => {
     console.log('doDelete');
     console.log(key);

     // Emit does db call to back end delete
     emit('deleteListRecord', key);
     areYouSure.value = null;
     fileToDelete.value = 'Unknown';

    };
   
   const triggerFileUploadedWithButton = async () => {
     console.log('trigger file upload');
     hiddenButton.value.click();
     
   };
   
   const fileUploadedWithButton = async (event) => {
     if('files' in event.target && event.target.files.length) {
       for(let li = 0 ; li < event.target.files.length; li++) {
         try {
           const file = event.target.files[li];
           if(file) {
             const encodedImage = await getBase64(file)
             const { name } = file;
             await handleNewFile({name: name, fileData: encodedImage})
           }
         } catch(e) {
           console.log('error');
           console.log(e);
         }
       }
     }
   }
   
   const uploadFile = async (ev) => {
     console.log('file Upload');
     console.log(ev);
     ev.preventDefault();
     
     if (ev.dataTransfer.items) {
       // Use DataTransferItemList interface to access the file(s)
       [...ev.dataTransfer.items].forEach((item, i) => {
         // If dropped items aren't files, reject them
         if (item.kind === 'file') {
           const file = item.getAsFile();
           console.log(`file[${i}].name = ${file.name}`);
           console.log(file);
           getBase64(file)
             .then((data) => {
               handleNewFile({
                 name: file.name,
                 fileData: data
               });
             })
             .catch(e => console.log(e));
         }
       });
     } else {
       // Use DataTransfer interface to access the file(s)
       [...ev.dataTransfer.files].forEach((file, i) => {
         console.log(`file[${i}].name = ${file.name}`);
          getBase64(file)
          .then((data) => {
            handleNewFile({
              name: file.name,
              fileData: data
            });
          })
          .catch(e => console.log(e));
          
        });
      }
    };

    const startDrag = (evt, li) => {
      console.log('start Drag attachments');
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      isDragging = li;
    }

    const onDrop = (li) => {
      console.log(`onDrop ${li} => ${isDragging}`);
      if(isDragging !== li && isDragging + 1 != li) {
        if(isDragging < li) {
          emit('dragItem', isDragging, li ? li - 1 : 0);
        } else {
          emit('dragItem', isDragging, li);
        }
      } else {
        console.log('skip move');
      }
      dropHere.value = null;
    }

    const dragEnter = (evt, li) => {
      if(isDragging !== li && isDragging + 1 != li) {
        dropHere.value = li;
      } 
    };

    const dragLeave = () => {
      dropHere.value = null;
    };

    const dropHere = ref(null);


    watch(props.data, () => {
      console.log('watch attachmentView props');
      // console.log(props.data.length);
      iData.value = props.data.filter((i) => {
        if(!(i.flags & IS_THUMBNAIL)) {
          return true;
        }
      });
      //iData.value[0] = {id:'junk',name:'junk',record:'junk',fileData: 'junk'};
    });

   const setDefaultImage = (item) => {
     console.log('set default image');
     console.log(item);
   }

    return {
      setDefaultImage,
      triggerFileUploadedWithButton,
      areYouSure,
      fileToDelete,
      doDeleteFile,
      uploadFile,
      changeFile,
      viewFile,
      deleteFile,
      handleNewFile,
      iData,
      viewImage,
      viewImageName,
      startDrag,
      onDrop,
      dragEnter,
      dragLeave,
      dropHere,
      fileUploadedWithButton,
      hiddenButton,
      chooseFile,
    }
  }
});
</script>
<style lang="scss" scoped>

@keyframes inactive {
  from {height: 0px; opacity: 100; }  
  to {height: 60px; opacity: 0; }
}
.errorSection {
  height: 0px;
  opacity: 0;
}
.inactive {
  display:none;
  height: 0px;
  opacity: 0;
  animation-name: inactive;
  animation-duration: 200ms;
}
.active {
  animation-name: inactiveError;
  animation-duration: 200ms;
  height: 60px;
  opacity: 100;
}
@keyframes inactiveError {
  from {height: 0px; opacity: 0; }  
  to {height: 60px; opacity: 100; }
}
.dropSection {
  background: #F6F6F6;
  color: grey;
  text-align:center;
  cursor:pointer;
}
.dropSection:hover {
    background-color: rgb(224, 224, 224);
  }
  .dropzone {
    background: #0004;
    border-radius: 5px;
    transition: all .3s ease;
  }

  
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: scale();
}
.hideInputBtn {
  display: none;
}
.hiddenButton {
  position:absolute;
  left: -30000px;
  top: -30000px;
}
</style>
