import {
	UnauthenticatedError,
	NotFoundError,
	InternalServerError,
	ForbiddenError,
} from '@/utils/errors';
import axios from 'axios';
import { authService } from './auth-service';

const baseUrl = process.env.VUE_APP_API_URL ? `${process.env.VUE_APP_API_URL}/api` : 'http://localhost:4000/api'
const _fetcher = axios.create({
	baseURL: baseUrl,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

_fetcher.interceptors.response.use(
	(res) => {
		// Return just the backend JSON response rather than an axios response config.
		return res.data;
	},
	(err) => {
		return Promise.reject(err);
	},
);

export class Fetcher {
	/**
	 * Helper function that makes a request equivalent to axios.request, but doesn't throw on a response
	 * code that isn't 2xx
	 *
	 * @param {AxiosRequestConfig} config
	 */
	async _req(config, options) {
    console.log('_req');
    console.log(config);
    console.log(options);
    console.log('');
		try {
			const res = await _fetcher.request(config, options);
			return res;
		} catch (err) {
			if (err.response && err.response.status === 401) {
				// Request successful, but the server responded with a status code
				// outside 2xx
				return err.response;
			}
			throw err;
		}
	}

	/**
	 * Gets a new access token and saves to token service, redirecting to login if no refresh
	 * token is available.
	 *
	 * @returns {Promise<string>}
	 */
	async getNewAccessToken() {
		const refreshToken = authService.refreshToken;
		if (!refreshToken) {
			throw new UnauthenticatedError();
		}

		const res = await _fetcher.request({
			method: 'post',
			url: '/token',
			data: {
				refreshToken,
			},
		});
		authService.accessToken = res.data.accessToken;
		return res.data.accessToken;
	}

	_setHeader(config, token) {
		if (!config.headers) {
			config.headers = {};
		}
		config.headers.Authorization = `Bearer ${token}`;
	}

	/**
	 * @param {AxiosRequestConfig} config
	 * @param {{ useAccessToken?: boolean } | undefined} options
	 * @returns
	 */
	async request(config, options) {
    console.log('fetcher options');
    console.log(config);
    console.log(options);
		try {
			if (options && options.useAccessToken) {
				const token = authService.accessToken;
				if (!token) {
          console.log('no token');
					throw new UnauthenticatedError('Unauthenticated');
				}
				this._setHeader(config, token);
			}

			let res = await this._req(config);
			if (res.status === 401) {
        console.log('got 401');
				// Retry the same request by trying a new access token.
				const refreshToken = authService.refreshToken;
				if (!refreshToken) {
          console.log('no refresh token');
					throw new UnauthenticatedError('Unauthenticated');
				}
				const accessToken = await this.getNewAccessToken();
				this._setHeader(config, accessToken);
				res = await _fetcher.request(config);
			}
      //Successful Request
			return res;
		} catch (err) {
			if (err.response) {
        console.log('fetcher error');
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.log(err.response.data);
				console.log(err.response.status);
				console.log(err.response.headers);
				if (err.response.status === 401) {
					throw new UnauthenticatedError('Unauthenticated', err.response);
				} else if (err.response.status === 403) {
					throw new ForbiddenError('Forbidden', err.response);
				} else if (err.response.status === 404) {
					throw new NotFoundError('Not found', err.response);
				} else if (err.response.status >= 400 && err.response.status < 500) {
          const error = new Error();
          console.log('db error');
          error.message = err.response.data.message;
          error.name = 'Bad Request';
					throw error;
				} else if (err.response.status >= 500) {
					throw new InternalServerError('Internal server error', err.response);
				} 

			} else if (err.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.log('No response', err.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', err.message);
			}
			console.log('Error', err);
			throw err;
		}
	}
}

export const fetcher = new Fetcher();
