<template>
  <v-app>
    <errorDialog 
      :showDialog="loadError" 
      title="Error" 
      :description="loadError" 
      yesText="Oh No!"
      @yes="loadError=''"
      style="z-index: 10000"
      />
    <loginCard style="z-index: 100000" :showDialog="user" @login="login" :loading="loginLoading" :errorStatus="loginError" @setError="loginError = ''"/>
    <selectCompType class="overlayContent"
      :showDialog="addCompType"
      :items="compTypes"
      :errorStatus="addDataError"
      :loading="loadAddData"
      @close="addDataError = ''; addCompType = false"
      @emitError="addDataError = ''"
      @selectCompType="openAddData(...arguments)"
      />
    <div class="overlay"  v-show="addData"></div>
    <div class="overlayContent" v-show="addData">
        <v-tooltip class="closeAddData" bottom color="blue darken-4">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="closeAddData close-button" text dense @click="closeDataInterface"  v-bind="attrs" v-on="on" color="red darken-4" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template> Discard Changes & Exit
        </v-tooltip>
      <addDataInterface 
        v-if="addData"
        :category="addDataCategory" 
        :subCategory="addDataSubCategory" 
        :SaleType="SaleType" 
        :DataRecord="DataRecord"
        :customFieldIface="customFieldIface"
        :customFieldError="customFieldError"
        :appLoading="appLoading"
        @close="closeDataInterface" 
        @save="closeDataInterface(true,true)"
        @deleteListRecord="deleteListRecord"
        @updateCustomFieldDataset="updateCustomFieldDataset"
        @addTableRow="addTableRow"
        @geoLocateAddress="geoLocateAddress"
        @toggleCustomFieldIface="toggleCustomFieldIface"
        @setCustomFieldIfaceError="setCustomFieldIfaceError"
        @addCustomField="addCustomField"
        @authError="logout"
        @setDefault="setDefault"
        @updateData="updateData"
        @updatePropertyType="updatePropertyType"
        @openProperty="openProperty"
        />
      <userAdminDialog
        v-if="userAdmin"
        :user="user"
        :showDialog="userAdmin"
        @close="userAdmin = false"
        />
    <confirmDialog 
      :showDialog="areYouSure" 
      title="Warning: Unsaved Data" 
      description="Are you sure you want to abandon your changes?" 
      noText="Nevermind."
      yesText="Yes, I'm sure."
      @no='areYouSure = false' 
      @yes="addData = areYouSure = false"
      :yesFirst="false"
      />

    </div>
    <v-navigation-drawer 
        class="drawer" 
        v-model="drawer"
        app
        priority="-1"
        width="512"
        disable-resize-watcher
        :clipped="true"
      >
      <v-btn class="mb-6 mt-4 mr-2 close-button"
             icon
             dense
             @click="toggleFilters"
             color="red darken-4"
             style="float:right;"
             >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="filters" style="padding: 15px 15px; float:left;">Filters</div>
      <div style="clear:both;"></div>
      <filters :filters="filters" :filterLists="filterLists" @updateFilter="updateFilter"/>
    </v-navigation-drawer>
    <v-app-bar
      app
      class="app-bar"
      dark
      extended-height="128"
      :clipped-left="true" :clipped-right="true"
      >
      <!-- Left Drawer -->
      <div class="d-flex align-center logo">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo_white.svg"
          transition="scale-transition"
          width="40"
          />
      </div>
      <div class="vertical-line"></div>
      <div class="atitle">
        J.H. Analysis<br>
        <div class="subtitle">
          APPRAISAL APPLICATION (v{{ appVersion }})
        </div>
      </div>
      <div class="signout" @click="logout">
        Sign Out
      </div>
      <v-spacer></v-spacer>
      <v-btn
        text
        outlined
        rounded
        color="white"
        @click="addCompType = true"
        >
        <v-icon>mdi-plus</v-icon>
        <span>Add Data</span>
      </v-btn>
      <template #extension>
        <v-toolbar light height="56" class="pt-4" style="width: 100%;">
          <v-row dense class="d-flex flex-row align-center">
            <v-col cols="2">
              <v-autocomplete v-model="category" @input="handleCategoryFilter" label="Category" :items="['', 'Commercial','Agriculture']" outlined dense class="m-2"></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete v-model="subCategory" @input="handleSubCategoryFilter" label="Sub-Category" :items="[ '', ...categories[category]]" outlined dense></v-autocomplete>
            </v-col>

              <v-col cols="auto" class="ml-4">
                <v-row  class="flex-nowrap">
                  <v-col cols="auto">
                    <v-btn
                        text
                        outlined
                        dense
                        @click="toggleFilters"
                        class="mb-6"
                        >
                        Filters
                      </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                        text
                        outlined
                        dense
                        @click="clearFilters"
                        class="mb-6 ml-0"
                        >
                        Clear Filters
                      </v-btn>
                  </v-col>
                  <v-col cols="auto" class="pr-0">
                      <v-btn text dense @click="View = !View" :color="View ? 'blue darken-4' : 'green darken-4'">
                        <v-icon>{{ View ? 'mdi-table-large' : 'mdi-map'}}</v-icon>
                        {{ View ? "Map" : "Comp" }}
                      </v-btn>
                    </v-col>
                    <v-divider vertical class="mt-3 mb-9"></v-divider>
                    <v-col cols="auto" class="pl-0">
                      <v-btn text dense @click="updatePropertyType(SaleType = !SaleType)" :color="SaleType ? 'green darken-4' : 'blue darken-4'">
                        <v-icon>{{ SaleType ? 'mdi-finance' : 'mdi-currency-usd' }}</v-icon>
                        {{ SaleType ? 'Lease' : 'Sale' }}
                      </v-btn>
                    </v-col>


                    <v-col cols="auto" class="pl-0">
                      <v-btn text dense @click="searchRecords" :color="'green darken-4'">
                        <v-icon>mdi-refresh</v-icon>
                        Reload
                      </v-btn>
                    </v-col>


                </v-row>
              </v-col>

              <v-spacer />

              <v-col align-self="end" cols="auto">
                <v-row class="align-content-end">
                    <v-tooltip bottom dense color="blue darken-4">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mb-8" icon dense @click="userAdmin = !userAdmin" color="blue darken-4" v-bind="attrs" v-on="on">
                          <v-icon>mdi-account</v-icon>
                        </v-btn>
                      </template>
                      User Admin
                    </v-tooltip>
                </v-row>
              </v-col>
              
            </v-row>
        </v-toolbar>
      </template>
    </v-app-bar>

    <v-main>
      <v-container v-if="View" fluid class="fill-height mapContainer">
        <mapData :items="tableItems" :loading="loadTable" :filter="filters" @updateSelectedItem="updateSelectedItem" :selectedItems="selectedItems" @openProperty="openProperty" @deleteProperty="deleteProperty" @geoCodeCheck='geoCodeCheck' :key="searchHack"/>
      </v-container>
      
      <v-container v-else fluid class="mainContainer">
        <tableData 
          :headers="tableHeaders" 
          :items="tableItems" 
          :loading="loadTable" 
          :filter="filters" 
          :selectedItems="selectedItems" 
          :filterOpen="drawer" 
          :key="searchHack"
          @updateSelectedItem="updateSelectedItem" 
          @openProperty="openProperty" 
          @deleteProperty="deleteProperty"
        />
      </v-container>
    </v-main>
    <v-navigation-drawer class="drawer blue lighten-5" 
                         v-model="selectedItems.length"
                         app
                         right
                         priority="1"
                         width="512"
                         style="height: 100vh;"
                         :clipped="true"
                         :mobile-breakpoint="100"
                         >
        <v-row class="mt-3">
          <v-col>
            <h1 class="drawer" style="padding: 0 15px;">Selected Comparables ({{ selectedItems.length }}) </h1>
              <selectedComparables @updateSelectedItem="updateSelectedItem" :selectedItems="selectedItems" :items="tableItems" @dragComparable="dragComparable" style="margin-bottom: 20px; height: calc(100vh - 325px);"/>
              <runReports :ids="selectedItems"/>
          </v-col>
        </v-row>
    </v-navigation-drawer>    
  </v-app>
</template>

<script>

import { defineComponent, onBeforeUnmount, onMounted, ref, watch, } from 'vue';
import { api } from '@/services/api-service';
import { authService } from '@/services/auth-service';
import filters from '@/components/filters';
import tableData from '@/components/tableData';
import mapData from '@/components/mapData';
import confirmDialog from '@/components/general/confirmDialog';
import userAdminDialog from '@/components/general/userAdminDialog';
import selectedComparables from '@/components/selectedComparables';
import loginCard from '@/components/loginCard';
import selectCompType from '@/components/selectCompType';
import addDataInterface from '@/components/addDataInterface';
import runReports from '@/components/runReports';
import errorDialog from '@/components/general/errorDialog';
import { maskValues, removeCommas, addCommas } from '@/utils/rules';
import * as schemaData from '@/data';

//const base64js = require('base64-js')


//Attachments Flags
const IS_DEFAULT = 2;
const IS_THUMBNAIL = 1;

export default defineComponent({
	name: 'App',
	setup() {
    console.log('setup app');
    const DataRecord = ref(); //Data that is being edited/modifed (open record)
    const View = ref(true); //View Type - Map on True, Table on False
    const areYouSure = ref(false); //Confirm abandoning edits
    const addCompType = ref(null); //What Category Is Selected (Opens AddData Interface)
    const SaleType = ref(false); // Sale or Lease Data
    const appVersion = '2.0';
    const drawer = ref(false); // Open Left Drawer Filters
    const category = ref(''); //Category Filter
    const subCategory = ref(''); //Sub-Category Filter
    const user = ref(authService.user);
    const loginLoading = ref(false);
    const userFlags = ref(0);
    const userAdmin = ref(false);
    const appLoading = ref(false);
    
    const compTypes = {
      Commercial: [
        'Apartment',
        'Land',
        'Medical',
        'Office',
        'Restaurant',
        'Retail',
        'Industrial',
        'Shopping',
        'Self Storage',
      ],
      Agriculture: [
        'Dairy',
        'Open Ground',
        'Permanent Plantings',
      ],
    };
    if(process.env.VUE_APP_ENV !== 'production') {
      compTypes.Agriculture.push('Test');
    }

    // Detect when the user hasn't interacted with the web app in 30 minutes. Although each API call will either refresh the token or log the user out, this is a failsafe - and provides better UX.
    let idleTimer = 1800; // 30 minutes
    let idleTimerId;    
    document.onmousemove = () => {
        idleTimer = 1800;
    };

    document.onmousedown = () => {
        idleTimer =  1800;
    };

    document.onkeydown = () => {
        idleTimer = 1800;
    };  

    const idleDetection = () => { 
        idleTimerId = setInterval(() => {
          idleTimer--;
          if (idleTimer === 0) {
            logout("You have been logged out due to inactivity.");
            clearInterval(idleTimer); // Stop the timer until a user is logged in
          }
      }, 1000);  
    }

    const categories = { //Sub-Categories Available
      '': [ ...compTypes.Commercial, ...compTypes.Agriculture ],
      Commercial: [ ...compTypes.Commercial ],
      Agriculture: [ ...compTypes.Agriculture ],
    };

    // Filter stuff
    const handleCategoryFilter = (categoryProp) => {
      (categoryProp in categories) ? filters.value.category = categoryProp : filters.value.category = '';
    };

    const handleSubCategoryFilter = (subcategoryProp) => {
      if (categories.Commercial.includes(subcategoryProp)) {
        filters.value.subcategory = subcategoryProp;
      } else if (categories.Agriculture.includes(subcategoryProp)) {
        console.log(categories.Agriculture)
        filters.value.subcategory = subcategoryProp;
      } else {
        filters.value.subcategory = '';
      }
    };

    const defaultFilters = {
      minSale: null,
      maxSale: null,
      minAcres: null,
      maxAcres: null,
      minSqft: null,
      maxSqft: null,
      apn: null,
      city: null,
      county: null,
      address: null,
      dateFrom: null,
      dateTo: null,
      plantings: null,
      waterDistrict: null,
      openGround: false,
      category: null,
      subcategory: null,
      propertyType: false,
    };
    const filters = ref({...defaultFilters});
    const filterLists = ref({
      apn: [],
      city: [],
      county: [],
      address: [],
      plantings: [],
      waterDistrict: [],
    });

    const updateFilter = (filterData) => {
      console.log('filter updated');
      console.log(filterData);
      filters.value = parseFilters(filterData);
    }

    const parseFilters = (filterData) => {
      filterData.minSale ? filterData.minSale = parseFloat(filterData.minSale) : filterData.minSale = null;
      filterData.maxSale ? filterData.maxSale = parseFloat(filterData.maxSale) : filterData.maxSale = null;
      filterData.minAcres ? filterData.minAcres = parseFloat(filterData.minAcres) : filterData.minAcres = null;
      filterData.maxAcres ? filterData.maxAcres = parseFloat(filterData.maxAcres) : filterData.maxAcres = null;
      filterData.minSqft ? filterData.minSqft = parseFloat(filterData.minSqft) : filterData.minSqft = null;
      filterData.maxSqft ? filterData.maxSqft = parseFloat(filterData.maxSqft) : filterData.maxSqft = null;
      filterData.dateFrom ? filterData.dateFrom = new Date(filterData.dateFrom) : filterData.dateFrom = null;
      filterData.dateTo ? filterData.dateTo = new Date(filterData.dateTo) : filterData.dateTo = null;
      return filterData;
    }

    const toggleFilters = () => {
      drawer.value = !drawer.value;
    }

    const clearFilters = () => {
      console.log('clear filters');
      filters.value = { ...defaultFilters };
      category.value = '';
      subCategory.value = '';
    }

    const updateFilterLists = (data) => {
      const extractData = data.data;
      if(extractData.address) {
        filterLists.value.address = extractData.address;
      }
      if(extractData.apns) {
        filterLists.value.apn = extractData.apns;
        // *Notice conversion from plural to singular above
      }
      if(extractData.city) {
        filterLists.value.city = extractData.city;
      }
      if(extractData.county) {
        filterLists.value.county = extractData.county;
      }
    }

    //Table Stuff
    const tableHeaders = [
      { text: 'Date of Sale',   value: 'saleDate' },
      { text: 'Planting Type',  value: 'plantingType' },
      { text: 'Price Per Acre', value: 'pricePerAcre' },
      { text: 'Acreage',        value: 'acreage' },
      { text: 'Water District', value: 'waterDistrict' },
    ];
    const loadTable = ref(false);

    /*

    const bounds = {
      north : 36.841181,
      south : 35.841181,
      east  : -120.8004913,
      west  : -119.8004913,
    };

    const lngSpan = bounds.east - bounds.west;
    const latSpan = bounds.north - bounds.south;


    const getPosition = () => {
      return {
        lat: bounds.south + latSpan * Math.random(),
        lng: bounds.west + lngSpan * Math.random(),
      }
    };
    */

    //Search Results
    const tableItems = ref([]);

    //Handling Item Selection
    const selectedItems = ref([]);
    const updateSelectedItem = (item, value) => {
      console.log(`select: ${item.id} => ${value}`);
      if(selectedItems.value.indexOf(item.id) > -1) {
        selectedItems.value.splice(selectedItems.value.indexOf(item.id),1);
      }
      //if(value) { //Used to push to bottom of stack
      else { // Used to select/deselect
        selectedItems.value.push(item.id);
      }
    };
    const dragComparable = (from, to) => {
      console.log(`dragComparable: ${from} - ${to}`);
      selectedItems.value.splice(to, 0, selectedItems.value.splice(from, 1)[0]);
    }

    const searchHack = ref(false);
    const searchRecords = async () => {
      console.log('searchrecords');
      try {
        const searchData = await api.searchRecords();
        console.log('process data');
        updateFilterLists(searchData)
        tableItems.value = searchData.data.searchData.map((i) => {
          return {
            id: i.id,
            propertiesId: i.propertiesId,
            apn: i.apn,
            selected: tableItems.value.reduce((k,l) => l.id === i.id && l.selected ? true : false, false), //Preserve selected items
            saleDate: i.recording_date,
            plantingType: i.variety,
            pricePerAcre: i.price_acre,
            acreage: i.total_acres,
            salePrice: i.price_sale,
            imageId: i.default_image_id ? i.default_image_id : null,
            waterDistrict: i.gsa,
            address: i.address,
            city: i.city,
            county: i.county,
            state: i.state,
            zip: i.zip,
            position: i.lat && i.lng ? { lat: parseFloat(i.lat), lng: parseFloat(i.lng) } : { lat: 0, lng: 0 },
            category: i.category,
            subcategory: i.subcategory,
            propertyType: i.propertyType,
          };
        });
        searchHack.value = !searchHack.value;
      } catch(e) {
        console.log('search error');
        if(e.message === 'Unauthenticated') {
          logout();
          loginError.value = 'Unauthenticated';
          return;
        }
        loadError.value += `${e.message} `;
        console.log(e);
      }
      
    }

    //const refreshDataTimer = setInterval(searchRecords, 1000 * 60); //Search for new records every minute
    const refreshDataTimer = null
    //Adding Data Stuff
    const addDataCategory = ref(null);
    const addDataSubCategory = ref(null);
    const addData = ref(false);
    const closeDataInterface = (force, refreshData) => {
      if(refreshData) {
        searchRecords();
      }
      if(force === true) {
        addData.value = false;
        return;
      }
      areYouSure.value = true;
    }

    const loadAddData = ref(false);
    const openRecord = async (id) => {
      console.log(`open record ${id}`);
    }
    
    const openAddData = async (category, subcategory) => {
      console.log('openAddData (new record)');
      try {
        loadAddData.value = true;
        addDataError.value = '';
        const dataObj = await api.addData(category, subcategory, SaleType.value);
        const { data } = dataObj;
        if(!data) {
          addDataError.value = 'Bad Record, no data.';
        }
        loadAddData.value = false;
        DataRecord.value = data

        addDataCategory.value = category;
        addDataSubCategory.value = subcategory;
        addCompType.value = false;
        addData.value = true;

      } catch(e) {
        if(e.message === 'Unauthenticated') {
          addCompType.value = false;
          logout("Could not authenticate the user. Please Log in.");
      }
        console.log(e);
        console.log('add data error');
        console.log(e.message);
        console.log(e.name);
        console.log(e.request);
        console.log(Object.keys(e));
        loadAddData.value = false;
        addDataError.value = e.message;
      }
    }

    const logout = (error = null) => {
      if (error && typeof error === 'string') { // The way logout is called in the template passes the event object into the function, so we need to check if it's a string.
        loginError.value = error; 
      }
      user.value = null;
      authService.clearCredentials();
      clearInterval(idleTimerId);
    }

    const loginError = ref();
    const addDataError = ref();

    const login = (username, password) => {
      loginLoading.value = true;
      api.login(username, password, true)
      .then((data) => {
        loginLoading.value = false;
        authService.storeCredentials(data);
        user.value = data;
        console.log('login succeeded');
        console.log(data);
      })
      .catch((e) => {
        loginLoading.value = false;
        switch(e.message) {
          case 'Unauthenticated':
          loginError.value = 'Username or Password invalid.';
          break;
          default:
          loginError.value = e.message;
          break;
        }
      });
    }

    // If a user refreshes the page, check to see if they are logged in. searchRecords will prompt a login if needed.
    onMounted(() => {
      if(user.value) {
        searchRecords();
        idleDetection();
      }
    });

    /* 
    However, on the first mount if the user isn't logged in, the data will not be automatically fetched unless the user manually presses the refresh button. 
    We can fix this by watching the user object. 
     */
    watch(user, (newUser) => {
      if(newUser) {
        searchRecords();
        idleDetection();
      }
    });

    const updateData = (dataSet, field, value, id) => {
      const recordID = DataRecord.value.id;
      const maskValue = value;
      switch(field) {
        case 'lat':
        case 'lng':
          storeStaticMap({lat: DataRecord.value.properties.lat, lng: DataRecord.value.properties.lng}, recordID);
        break;
      }
      let data = DataRecord.value[dataSet];
      if(dataSet == 'record') {
        data = DataRecord.value[field];
      }
      console.log(data);
      console.log(DataRecord.value);
      if(data !== null && data !== undefined) {
        console.log('got data');
        if(Array.isArray(data)) {
          console.log('got array data');
          const itemKey = data.reduce((i, j, k) => {
            console.log(j);
            if(j.id === id) {
              return k;
            }
            return i;
          }, null);
          console.log(itemKey);
          data[itemKey][field] = maskValue;
        } else {
          console.log('got data');
          console.log(data);
          data[field] = maskValue;
        }
      } else {
        console.log('no data');
      }
    };

    const getDefautltCustom = (type) => {
      switch(type) {
      case 'Text': 
      default: return {
        value: '',
        rules: [],
      };
      }
    }
    const updateCustomFieldDataset = async(dataSet, record, done, err) => {
      try {
        console.log('update custom field dataset');
        console.log(dataSet);
        console.log(record)
        const {id, name, value, fieldType, [dataSet]:recordId} = record;
        const customDataSet = `${dataSet}Custom`;
        console.log(id);
        console.log(name);
        console.log(value);
        console.log(fieldType);
        console.log(recordId)
        const data = await api.setRecord(customDataSet, name, value, id, recordId, fieldType);
        console.log(data);
        done();
        //*/
        //customFieldError.value='do db call for update custom record';
      } catch(e) {
        // appLoading.value = false;
        if (e.message === 'Unauthenticated') {
          logout();
        }
        done();
        err(e.message);
      }
    }
    
    const addCustomField = async(dataSet, field, type) => {
      console.log('addCustomField');
      try {
        const customDataSet = `${dataSet}Custom`;
        const dataSetRecord = DataRecord.value[dataSet];
        console.log('dataSetRecord VVV');
        console.log(dataSetRecord);
        console.log('dataSetRecord ^^^');
        if(!field) {
          customFieldError.value = 'Field Name is required.';
          return;
        }

        let id = null;

        if(Array.isArray(dataSetRecord)) {
          console.log('reduce id array');
          id = DataRecord.value[dataSet].reduce((i, j) => {
            i.push(j.id);
            return i;
          }, []);
        } else {
          id = DataRecord.value[dataSet].id;
          if(!id) {
            customFieldError.value += `Record ID is missing.\n`;
            return;
          }
        }
        console.log('did we get ids');
        console.log(id);
        appLoading.value = true;
        const { data } = await api.addCustomRecord(customDataSet, field, type, id);
        console.log('got data');
        console.log(data);
        appLoading.value = false;
        //When the data set is Table Data, it will be an array, iterate over data individually

        if(Array.isArray(dataSetRecord)) {
          console.log('table record');
          if(!Array.isArray(data)) {
            customFieldError.value += `Invalid reponse data.\n`;
            return;
          }
//          for(let li in dataSetRecord) {
//            console.log(`dataSetRecord ${li}`);
//            console.log(dataSetRecord[li]);
//            if(!DataRecord.value[dataSet][li].custom) {
//              DataRecord.value[dataSet][li].custom = [];
//            }
            for(let lj in data) {
              const record =  data[lj];
              console.log('id');
              console.log(id);
              console.log('record');
              console.log(record);
              DataRecord.value[dataSet].reduce((i, j, li) => {
                console.log('i');
                console.log(i);
                console.log('j');
                console.log(j);
                console.log(`${j.id} === ${record[dataSet]}`);
                console.log('');

                if(j.id === record[dataSet]) {
                  console.log(`matched record ${li}`);
                  if(!('custom' in DataRecord.value[dataSet][li])) {
                    console.log('make new custom');
                    console.log(record);
                    DataRecord.value[dataSet][li].custom = record;
                  } else {
                    console.log(`append to custom: ${dataSet}, ${li}, ${DataRecord.value[dataSet][li].custom.length} `);
                    console.log(record);
                    console.log(DataRecord.value[dataSet][li]);
                    const setKeys = Object.keys(DataRecord.value[dataSet][li]);
                    setKeys.reduce((i,j) => {
                      if(Array.isArray(DataRecord.value[dataSet][li][j])) {
                        console.log(`    ${j} => ${DataRecord.value[dataSet][li][j].length}`);
                        console.log(DataRecord.value[dataSet][li][j]);
                      } else {
                        console.log(`    ${j} => ${DataRecord.value[dataSet][li][j]}`);
                      }
                    }, null);
                    console.log('finish append');
                    DataRecord.value[dataSet][li].custom.push({ ...record });
                  }
                  return record;
                }
                return i;
              } , null);
            }
            
//          }
        } else {
          console.log('standard record');
          DataRecord.value[dataSet].custom.push(data);
        }

        customFieldError.value='';
        customFieldIface.value = '';
      } catch(e) {
        if (e.message === 'Unauthenticated') {
          logout("Could not authenticate the user. Please Log in.");
        }
        console.log(e);
        appLoading.value = false;
        customFieldError.value = e.message;
        console.log(e.message);
    }
    };

    const toggleCustomFieldIface = (status) => {
      customFieldError.value = '';
      customFieldIface.value = status;
    }

    const setCustomFieldIfaceError = (error) => {
      console.log('set custom iface error');
      customFieldError.value = error;
    }

    const customFieldIface = ref('');
    const customFieldError = ref('');

    const deleteListRecord = async (schema, key, done, error) => {
      try {
        console.log('do db call for deleteListRecord');
        console.log(schema, key);
        await api.deleteListRecord(key, schema);
        console.log('done');
        const recordToDelete = DataRecord.value[schema].reduce((i,j,k) => {
          console.log('j');
          console.log(j);
          if(j.id === key) {
            console.log(`delete ${k}`);
            return k;
          }
          return i;
        }, null)
        if(recordToDelete || recordToDelete === 0) {
          console.log(`do delete ${recordToDelete} of ${DataRecord.value[schema].length}`);
          //DataRecord.value[schema].splice(recordToDelete,1);
          DataRecord.value[schema] = [{id:'junk',name:'junk',record:'junk',fileData: 'junk'},{id:'junk',name:'junk',record:'junk',fileData: 'junk'}];
        }
        done();
      } catch(e) {
        if (e.message === 'Unauthenticated') {
          logout("Could not authenticate the user. Please Log in.");

        }

        done();
        console.log(e);
        error(e.name);
      }
    }

    const addTableRow = async (compType, tableRow) => {
      console.log(`addTableRow to ${compType}`);
      const record = DataRecord.value;
      console.log(record);
      if(compType != 'attachments') {
        let custom = [];
        if(record[compType].length) { //Get custom spec from first record if it exists
          const newCustom = record[compType][0]['custom'].map((i) => {
            const n = { ...i };
            n.value = ''; 
            return n;
          });
          custom = newCustom;
        }
        if(record && 
           compType in record) {
          tableRow.custom = [ ...custom ];
          const testMe = { ...tableRow };
          record[compType].push(testMe);
        }
      } else {
        if(record && 
           compType in record) {
          const NewRows = [ ...tableRow ];
          NewRows.forEach(i => {
            console.log('append attachment');
            record[compType].push(i);
          });
          // record[compType].push(testMe);
        }
      }
    }

    let google_api_retries = 0;

    const geoCodeCheck = () => {
      try {

        console.log('geocode check');
        //if(!('google' in window) && google_api_retries > 5) {
        if(!('google' in window) && google_api_retries < 5) {
          console.log(`api not loaded ${google_api_retries}`);
          //google_api_retries++;
          setTimeout(() => { geoCodeCheck(); },300);
          return;
        }

        console.log('api is loaded');
        if(google_api_retries >= 5) {
          loadError.value = 'error loading google maps';
          return;
        }

        const geocoder = new window.google.maps.Geocoder();
        for(let li in tableItems.value) {
          const tableItem = tableItems.value[li];
          if(tableItem) {
            const { position } = tableItem;
            if(position) {
              console.log('check position: ');
              console.log(position);
              const { lat, lng } = position;
              if(!lat && !lng) {
                console.log('get position');
                const state = 'CA';
                const { address, city, zip } = tableItem;
                const faddress = `${address?address:''}, ${city?city:''}, ${state?state:''} ${zip?zip:''}`;
                if(geocoder) {
                  console.log(`do geocode on: ${faddress}`);
                  geocoder.geocode({address: faddress}, (results, status ) => {
                    console.log(`geocode complete ${status}`);
                    if(status == window.google.maps.GeocoderStatus.OK) {
                      const position = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() }
                      console.log('we have a position!!!');
                      console.log(position);
                      console.log(tableItems.value[li]);
                      tableItems.value[li].position = position;
                      console.log(position);
                      storeStaticMap(position, tableItems.value[li].id);
                      api.setRecord('properties', 'lat', results[0].geometry.location.lat(), tableItems.value[li].propertiesId)
                      .catch(e => {
                        console.log('save latitude error');
                        console.log(e);
                        loadError.value += `${e.message} `;
                      });
                      api.setRecord('properties', 'lng', results[0].geometry.location.lng(), tableItems.value[li].propertiesId)
                      .catch(e => {
                        console.log('save longitude error')
                        console.log(e);
                        loadError.value += `${e.message} `;
                      });
                    } else {
                      console.log('geocoder error');
                      console.log(status);
                    }
                  });
                }
              }
            }
          }
        }
      } catch(e) {
        console.log('geocode error');
        console.log(e);
        loadError.value += e.message;
      }
    };

    const geoLocateAddress = async (geoError, done) => {
      try {
        const { properties, id } = DataRecord.value;
        if(!properties) { return; }
        const state = 'CA';
        const { address, city, zip } = properties;
        const faddress = `${address?address:''}, ${city?city:''}, ${state?state:''} ${zip?zip:''}`;
        
        if(!window.google) {
          console.log('no api')
          setTimeout(geoLocateAddress(geoError, done) , 200);
          return;
        }
        if( address && city && zip) {
          const geocoder = new window.google.maps.Geocoder();
          if(geocoder) {
            console.log(`do geocode on: ${faddress}`);
            await geocoder.geocode({address: faddress}, async (results, status ) => {
              if(status == window.google.maps.GeocoderStatus.OK) {
                console.log('done');
                console.log(results);
                if(faddress) {
                  properties.lat = results[0].geometry.location.lat();
                  properties.lng = results[0].geometry.location.lng();
                  console.log('call done callback');
                  storeStaticMap(properties, id);
                  await done(properties.lat, properties.lng);
                } else {
                  console.log('return this');
                  return { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() }
                }
              } else {
                console.log('geocoder error');
                console.log(status);
              }
            });
          }
        }
      } catch(e) { 
        console.log('geocode error');
        console.log(e);
        geoError(e);
        // loadError.value += e.message;
      }
    }
    const storeStaticMap = async (location, recordID) => {
      console.log('store static map');
      console.log(location);
      console.log(recordID);
      if(location && recordID) {
        await api.getMapImage(location)
          .then(async (data) => {

            

            console.log('map image data');
            console.log(data);
            const base64 = 'nothing';
            //const translatedData = Uint8Array.from(data, x => x.charCodeAt(0))
            //console.log(translatedData);
            //
            //console.log('got map');
            //const arBuffer = await data.arrayBuffer();
            //console.log(arBuffer);
            //console.log(arBuffer.Uint8Array);
            //const base64 = btoa(translatedData);
            //console.log('base64');
            //console.log(base64);
            //console.log(unescape(data));
            //const Int32 = new Int32Array(data);
            //console.log('Int32');
            //console.log(Int32);
            //const IReduced = Int32.reduce((data, byte) => {
            //  console.log(`reduce: ${byte} => ${String.fromCharCode(byte)}`);
            //  return data + String.fromCharCode(byte)
            //},'');
            //console.log('IReduced');
            //console.log(IReduced);
            //const base64 = btoa(unescape(data));
            //console.log('base64');
            //console.log(base64);
            //console.log('doneBase64');
            //console.log();
            api.storeMapImage(base64, recordID)
                .then(() => {
                  console.log('map image storage complete');
                })
                .catch(e => {
                  loadError.value += 'Error: failed to store a map image';
                  console.log(e);
                });            


            //const canvas = document.createElement('canvas');
            //const ctx = canvas.getContext('2d');
            //const img = new Image();
            //img.src = btoa(new Int32Array(data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
            //await ctx.drawImage(img, 0, 0);
            //console.log('setup image load handler');
            //if(img.complete) {
            //  console.log('image is complete already');
            //  // const b64Map = canvas.toDataURL("image/png");
            //  //api.storeMapImage(b64Map, recordID)
            //  //  .then(() => {
            //  //    console.log('map image storage complete');
            //  //  })
            //  //  .catch(e => {
            //  //    loadError.value += 'Error: failed to store a map image';
            //  //    console.log(e);
            //  //  });
            //}
            //else {
            //  console.log('adding load callback');
            //  img.onload = () => {
            //    console.log('image loads');
            //    
            //    //const b64Map = canvas.toDataURL("image/png");
            //    //api.storeMapImage(b64Map, recordID)
            //    //  .then()
            //    //  .catch(e => {
            //    //    loadError.value += 'Error: failed to store a map image';
            //    //    console.log(e);
            //    //  });
            //    console.log('image drawn');
            //  }
            //}
            
            //console.log('skip image loading');
            //console.log('html canvas data');
/*
              api.storeMapImage(btoa(unescape(encodeURIComponent(data))), recordID)
                .then()
                .catch(e => {
                  loadError.value += 'Error: failed to store a map image';
                  console.log(e);
                });

*/

            //const b64Map = btoa(encodeURIComponent(data));
          })
          .catch(e => {
            console.log('image loading error');
            loadError.value += 'Google Maps failed to return a map image';
            console.log(e);
          })
      } else {
        loadError.value += `Error: ${location ? 'location' : 'record'} is missing ${location} => ${recordID}`
      }
      console.log('exit function');
      console.log('');
    }

    console.log(getDefautltCustom);
    watch(user,(newUser) => {
      const { user } = newUser;
      if(user) {
        const { flags } = user;
        if(flags) {
          userFlags.value = flags;
        } else {
          userFlags.value = 0;
        }
      }
    });
    const loadError = ref('');
    const deleteProperty = async (id) => {
      console.log('delete property');
      await api.deleteProperty(id);
      searchRecords();
    };
    const openProperty = async (id, dupData) => {
      console.log(`openProperty ${id}`);
      try {
        loadError.value = '';
        const dataId = await api.copyData(id, dupData ? true : false);
        const dataObj = await api.openData(dataId.data.id);
        const { data } = dataObj;
        if(!data) {
          loadError.value="Invalid Data Object was returned.";
          return;
        }
        const { category, subcategory } = data;
        if(!category) {
          loadError.value="Category is missing.";
          return;
        }
        if(!subcategory) {
          loadError.value="SubCategory is missing.";
          return;
        }
        console.log(`set categories ${data.category} => ${data.subcategory}`);
        addDataCategory.value = data.category;
        addDataSubCategory.value = data.subcategory;
        const dataKeys = Object.keys(data);
        dataKeys.forEach((i) => {
          const cSchema = schemaData[`${i}Schema`];
          if(cSchema) {
            let type = typeof(data[i]);
            if(type === 'object') {
              if(Array.isArray(data[i])) {
                type = 'array';
              }
            }
            console.log(`type: ${type}`);
            console.log(data[i]);
            switch(type) {
            case 'object':
              const fields = Object.keys(data[i]);
              fields.forEach((j) => {
                const fType = cSchema.filter(i => i.value === j).reduce((i, j) => j.type, null);
                if(fType) {
                  switch(fType) {
                  case 'percent':
                  case 'number':
                  case 'decimal':
                  case 'money':
                    data[i][j] = addCommas(maskValues(fType, removeCommas(data[i][j])) === '' ? null : maskValues(fType, removeCommas(data[i][j])));
                    break;
                  default:
                    data[i][j] = maskValues(type, data[i][j]) === '' ? null : maskValues(type, data[i][j]);
                    break;

                  }
                }
              });
              break;
            case 'array':
              const rows = data[i];
              rows.forEach((k,l) => {
                const fields = Object.keys(data[i][l]);
                fields.forEach((j) => {
                  const fType = cSchema.filter(i => i.value === j).reduce((i, j) => j.type, null);
                  if(fType) {
                    //console.log(data[i][l][j]);
                    switch(fType) {
                    case 'percent':
                    case 'number':
                    case 'decimal':
                    case 'money':
                      data[i][l][j] = addCommas(maskValues(fType, removeCommas(data[i][l][j])) === '' ? null : maskValues(fType, removeCommas(data[i][l][j])));
                      break;
                    default:
                      data[i][l][j] = maskValues(type, data[i][l][j]) === '' ? null : maskValues(type, data[i][l][j]);
                      break;
                    }
                  }
                });
              });
              break;
            default:
              //nothing
              break;
            }
          }
        });
        DataRecord.value = data;
        addData.value = true;
      } catch(e) {
        if (e.message === "Unauthenticated") {
          logout("Could not authenticate the user. Please Log in.");
        }
        console.log(e);
        console.log('add data error');
        console.log(e.message);
        console.log(e.name);
        console.log(e.request);
        console.log(Object.keys(e));
        loadError.value = e.message;
      }
    }


    const setDefault = async (iData, done, error) => {
      console.log('set default');
      console.log(iData);
      try {
        const newDefault = await api.setDefaultAttachment(iData);
        const { data } = newDefault;

        let updateThumbnail = false;
        DataRecord.value.attachments.forEach((i,j) => {
          console.log(i);
          const { flags } = i;
          if(flags || flags === 0) {
            i.flags &= ~IS_DEFAULT;
            i.isDefault = 0;
            if(flags & IS_THUMBNAIL) {
              updateThumbnail = true;
              DataRecord.value.attachments[j] = data;
            }
            console.log(`${i.id} === ${iData.id}`);
            if(i.id === iData.id) {
              console.log(`SET DEFAULT TO ${i.id}`);
              i.flags |= IS_DEFAULT;
              i.isDefault = 1;
            }
          }
        });
        if(!updateThumbnail) {
          DataRecord.value.attachments.push(data);
        }
        console.log('app done');
        /*
        const { id } = data;
        if(id) {
          const attachmentID = DataRecord.value.attachments.reduce((i, j, k) => {
            console.log(`${j.id} === ${id}`);
            if (j.id === id) {
              console.log(`got it ${k}`);
              return k;
            }
            return i;
          }, null);
          console.log(`attachmentID ${attachmentID}`);
          if(attachmentID !== null) {
            console.log('make default');
            console.log(DataRecord.value.attachments[attachmentID]);
            DataRecord.value.attachments[attachmentID].flags |= 2;
            DataRecord.value.attachments[attachmentID].isDefault = 1;
          } else {
            console.log('skip default set');
          }
        }
        // */
        done();
      } catch(e) {
        console.log('app error');
        error(e);
      }
    }

    onBeforeUnmount(() => {
      //clearInterval(refreshDataTimer)
      console.log(refreshDataTimer);
    });

    const updatePropertyType = (newType) => {
      console.log('updatePropertyType');
      SaleType.value = newType;
      if(DataRecord.value && 'propertyType' in DataRecord.value) {
        DataRecord.value.propertyType = newType;
      }
      filters.value.propertyType = newType;
    }



    const test = async () => {
      try {
        const result = await api.test();
        const { data } = result
				const fileLink = document.createElement('a');
        fileLink.href = `data: application/vnd.openxmlformats;base64,${data}`;
				fileLink.setAttribute('download', `shantest.docx`);
				document.body.appendChild(fileLink);
				fileLink.click();


      } catch(e) {
        console.log(e);
        loadError.value = e.message;
      }
    }

    searchRecords();

    //Return Reactive Data
		return {
      updatePropertyType,
      test,
      storeStaticMap,
      searchHack,
      setDefault,
      addCustomField,
      setCustomFieldIfaceError,
      loadError,
      openProperty,
      openRecord,
      searchRecords,
      userFlags,
      userAdmin,
      geoLocateAddress,
      updateCustomFieldDataset,
      deleteListRecord,
      DataRecord,
      loadAddData,
      addDataError,
      login,
      loginLoading,
      logout,
      loginError,
      user,
      areYouSure,
      openAddData,
      compTypes,
      addCompType,
      categories,
      closeDataInterface,
      addData,
      dragComparable,
      updateSelectedItem,
      selectedItems,
      loadTable,
      updateFilter,
      clearFilters,
      filters,
      filterLists,
      toggleFilters,
      drawer,
      appVersion,
      View,
      SaleType,
      tableItems,
      tableHeaders,
      category,
      subCategory,
      addDataCategory,
      addDataSubCategory,
      addTableRow,
      geoCodeCheck,
      customFieldIface,
      toggleCustomFieldIface,
      customFieldError,
      appLoading,
      updateData,
      handleCategoryFilter,
      handleSubCategoryFilter,
      deleteProperty,
		};
	},
	components: {
    errorDialog,
    filters,
    tableData,
    mapData,
    selectedComparables,
    addDataInterface,
    runReports,
    selectCompType,
    confirmDialog,
    loginCard,
    userAdminDialog,
	},
});
</script>
<style lang="scss">
  .app-bar {
    background: linear-gradient(to right,#203D69, #005EBA);
    margin:0;
    padding:0;
  }

  body {
    overflow: hidden;
  }
.logo {
  margin-left:30px;
}
.vertical-line {
  width: 2px; 
  height: 70%;
  border-left: 1px solid white;
  margin: 5px;
  padding-left:10px;
}
.atitle {
  font-weight:500;
  font-size:16pt;
}
.subtitle {
  font-weight:200;
  font-size:8pt;
}
.signout {
  cursor:pointer;
  text-decoration: underline;
  font-weight:100;
  font-size: 12pxh;
  margin-top:30px;
  margin-left: 20px;
}
.v-toolbar__extension {
  padding: 0px !important;
}
.v-toolbar__extension .v-toolbar__content {


}
.mainContainer {
  margin-top:20px;
  overflow:auto; // This is what causing the card to be hidden behind the maincontainer
  max-height: calc(100vh - 135px);
}
.mapContainer {
  margin-top: 10px;
}
.overlay {
  background: black;
  opacity: 30%;
  position:absolute;
  width: 100vw;
  height:100vh;
  z-index: 6000;
}
.overlayBlur {
  filter: blur(2px);
  position:absolute;
  width: 100vw;
  height:100vh;
  z-index: 5990;
}
.overlayContent {
  background: transparent;
  position:absolute;
  width: 100vw;
  height:100vh;
  z-index: 6100;
}
.closeAddData {
  position:absolute;
  top: 10px;
  right: 10px;
}

	.drawer {
    padding-top: 25px;
    overflow:hidden;
	}
  .filters {
    padding-top:15px;
    font-size: 18pt;
    font-weight: 800;
  }
	html {
			overflow: hidden !important;
	}

	body {
			overflow: hidden;
	}
	.close-button {
	box-shadow: 0px 2px 8px rgba(11, 28, 54, 0.15);
	border: none;
	}
	.vertical-line {
	width: 2px; 
	height: 70%;
	border-left: 1px solid white;
	margin: 5px;
	padding-left:10px;
	}
	.atitle {
	font-weight:500;
	font-size:16pt;
	}
	.subtitle {
	font-weight:200;
	font-size:8pt;
	}
	.signout {
	cursor:pointer;
	text-decoration: underline;
	font-weight:100;
	font-size: 12pxh;
	margin-top:30px;
	margin-left: 20px;
	}
	.mainContainer {
	margin-top: 30px;
	overflow:auto; // This is what causing the card to be hidden behind the maincontainer
	max-height: calc(100vh - 135px);
	}
	.mapContainer {
	margin-top: 10px;
	}
	.overlay {
	background: black;
	opacity: 30%;
	position:absolute;
	width: 100vw;
	height:100vh;
	z-index: 6000;
	}
	.overlayBlur {
	filter: blur(2px);
	position:absolute;
	width: 100vw;
	height:100vh;
	z-index: 5990;
	}
	.overlayContent {
	background: transparent;
	position:absolute;
	width: 100vw;
	height:100vh;
	z-index: 6100;
	}
	.closeAddData {
    position:absolute;
    top: 10px;
    right: 10px;
    background: #fffe;
	}
</style>
