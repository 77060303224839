<template>
<div style="position:fixed; bottom: 0; background: #E3F2FD; width: 100%; padding: 10px 20px;">
    <v-row dense :class="{ errorSection: true, active: errorStatus !== '', inactive: errorStatus === '' }">
      <v-col cols="10" class="pl-4 pt-2" style="color: red;" >
        {{ errorStatus !== '' ? `Error: ${errorStatus}` : '' }}
      </v-col>
      <v-col cols="1">
        <v-tooltip bottom color="red">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-show="errorStatus !== ''" icon @click="closeError" color="red darken-1" v-bind="attrs" v-on="on">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          Close Error
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-column align-center mt-0">
        <v-row class="mb-3 mt-0">
          <v-col cols="12">
            <v-btn
              rounded
              block
              color="primary"
              @click="generateReports(true)"
              :disabled="!wordDoc && !excelSheet"
              >
                <v-icon v-if="!loadingExcel">mdi-file-excel-box</v-icon>
                <v-progress-circular indeterminate v-else size="25" class="mr-2"></v-progress-circular>
                <span class="mr-2">{{ DownloadingExcel }} Excel Doc</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mb-3 mt-0">
          <v-col cols="12">
            <v-btn
              rounded
              block
              color="primary"
              @click="generateReports(false)"
              :disabled="!wordDoc && !excelSheet"
              >
                <v-icon v-if="!loadingDoc">mdi-file-word-box</v-icon>
                <v-progress-circular indeterminate v-else size="25" class="mr-2"></v-progress-circular>
                <span class="mr-2">{{ DownloadingDoc }} Word Doc</span>
            </v-btn>
          </v-col>
        </v-row>
    </v-row>

</div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { api } from '@/services/api-service';

export default defineComponent({
	name: 'runReports',
  props: {
    ids: {
      type: Array,
      default: () => [],
    }
  },
  setup(props) {
    const DownloadingExcel = computed(() => loadingExcel.value ? 'Downloading' : 'Download' );
    const DownloadingDoc = computed(() => loadingDoc.value ? 'Downloading' : 'Download' );
    const loadingExcel = ref(false);
    const loadingDoc = ref(false);
    const errorStatus = ref('');
    console.log('runReports');
    const wordDoc = ref(true);
    const excelSheet = ref(false);
    const generateReports = (excel) => {
      if(excel) {
        console.log('IDS:');
        console.log(props.ids);
        loadingExcel.value=true;
        api.getExcel(props.ids).then((result) => {
          const { data } = result
					const fileLink = document.createElement('a');
          fileLink.href = `data: application/vnd.ms-excel;base64,${data}`;
					fileLink.setAttribute('download', `report.xlsx`);
					document.body.appendChild(fileLink);
					fileLink.click();
          loadingExcel.value = false;
        })
          .catch((e) => {
            console.log('error');
            console.log(e);
            loadingExcel.value=false;
          });
      } else {
        console.log('IDS:');
        console.log(props.ids);
        loadingDoc.value=true;
        api.getDoc(props.ids).then((result) => {
          console.log(result);
          //*
          const { data } = result
					const fileLink = document.createElement('a');
          fileLink.href = `data: application/vnd.openxmlformats;base64,${data}`;
					fileLink.setAttribute('download', `report.docx`);
					document.body.appendChild(fileLink);
					fileLink.click();
          //*/
          loadingDoc.value = false;
        })
          .catch((e) => {
            console.log('error');
            console.log(e);
            loadingDoc.value=false;
          });

      }

    }
    const closeError = () => {
      errorStatus.value = '';
    }
    return {
      closeError,
      errorStatus,
      generateReports,
      loadingExcel,
      loadingDoc,
      DownloadingExcel,
      DownloadingDoc,
      wordDoc,
      excelSheet,
    };
  }
});
</script>
<style scoped>
.black--text /deep/ label {
    color: black;
}
@keyframes inactive {
  from {height: 0px; opacity: 100; }  
  to {height: 60px; opacity: 0; }
}
.errorSection {
  height: 0px;
  opacity: 0;
}
.inactive {
  height: 0px;
  opacity: 0;
  animation-name: inactive;
  animation-duration: 200ms;
}
.active {
  animation-name: inactiveError;
  animation-duration: 200ms;
  height: 60px;
  opacity: 100;
}
@keyframes inactiveError {
  from {height: 0px; opacity: 0; }  
  to {height: 60px; opacity: 100; }
}

</style>
