<template>
  <gmap-map
    :zoom="12"
    :center="currentLocation"
    :options="{
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
      ],
    }"
    @click="handleInfoWindowClose"
    @contextmenu="showContextMenu"
    style="width: 100%; height: 100%"
  >
    <gmap-marker
      :key="index"
      v-for="(m, index) in fItems"
      :position="m.position"
      :label="getLabel(m)"
      :icon="getLabel(m) ? selectedMarker : defaultMarker"
      @mouseover="setCurrentHover(m)"
      @mouseout="setCurrentHover(null)"
      @click="selectMarker(m)"
      @dblclick="selectItem(m)"
    ></gmap-marker>
    <gmap-info-window
      :options="infoOptions"
      :position="infoWinPos"
      :opened="infoWinOpen"
      @closeclick="handleInfoWindowClose"
    >
      <div
        style="width: 400px; overflow: hidden"
        @contextmenu.prevent="showContextMenu"
      >
        <propertyCard
          :displayComponent="true"
          class="propertyCard"
          :id="propertyCardData.id"
          :salePrice="propertyCardData.salePrice"
          :saleDate="propertyCardData.saleDate"
          :otherData="propertyCardData.otherData"
          :imageId="propertyCardData.imageId"
          :address="propertyCardData.address"
          :acreage="propertyCardData.acres"
          :selectedItems="selectedItems"
          :currentView="'mapView'"
          @close="propertyCardData.show = false"
          @clickCard="$emit('openProperty', propertyCardData.id)"
          @deleteCard="$emit('deleteProperty', propertyCardData.id)"
          @selectCard="selectItemFromId(propertyCardData.id)"
        />

        <v-menu
          v-model="contextMenuShown"
          :position-x="contextMenuX"
          :position-y="contextMenuY"
          absolute
          offset-y
          style="z-index: 9999"
        >
          <div style="background: white">
            <v-list-item v-for="(item, index) in contextMenuItems" :key="index">
              <button>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </button>
            </v-list-item>
          </div>
        </v-menu>
      </div>
    </gmap-info-window>
  </gmap-map>
</template>

<script>
import { 
  defineComponent, 
  ref, 
  //*
  onMounted,
  /*/
  onUpdated,
  //*/
  watch, 
} from "vue";
import defaultMarker from "@/assets/default_marker.png";
import selectedMarker from "@/assets/selected_marker.png";
import propertyCard from "@/components/propertyCard";

export default defineComponent({
  name: "mapData",
  components: { propertyCard },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    console.log('initialize MapData');
    const selectedRows = ref([...props.selectedItems]);
    const infoWinOpen = ref(false);
    const infoWinPos = ref({
      lat: 47.374592,
      lng: 8.548867,
    });

    const infoOptions = ref({
      pixelOffset: {
        width: 0,
        height: -35,
      },
    });

    const search = ref("");

    const currentSelected = ref(null);
    const currentHover = ref(null);

    const setCurrentHover = (newHover) => {
      currentHover.value = newHover;
    };

    const setCurrentSelected = (newSelected) => {
      currentSelected.value = newSelected;
    };

    const contextMenuItems = [
      { text: "Copy", icon: "mdi-eye" },
      { text: "Duplicate", icon: "mdi-pencil" },
    ];
    const contextMenuShown = ref(false);
    const contextMenuX = ref(0);
    const contextMenuY = ref(0);
    const showContextMenu = (event) => {
      console.log('showcontextmenu');
      contextMenuShown.value = false;
      contextMenuX.value = event.clientX;
      contextMenuY.value = event.clientY;
      contextMenuShown.value = true;
    };

    const handleInfoWindowClose = () => {
      console.log('clicked');
      currentSelected.value = null;
      infoWinOpen.value = false;
    };

    const filterItems = (item) => {
      console.log('item');
      console.log(item);
      //eslint-disable-line
      /*
      const oSearch = search.substring(
        search.indexOf(sDelimnitor) + sDelimnitor.length
      );
      const sTest = Object.keys(item).reduce((i, j) => {
        if (String(item[j]).indexOf(oSearch) + 1 || i) {
          return true;
        }
        return false;
      }, false);
      */

      let filtered = true;

      if (~~props.filter.minSale && ~~item.salePrice) {
        console.log(`minSale ${~~props.filter.maxSale} <= ${~~item.salePrice}`);
        if (~~props.filter.minSale <= ~~item.salePrice) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`minSale ${filtered ? 'true' : 'false'}`);
      }


      if (~~props.filter.maxSale && ~~item.salePrice) {
        console.log(`maxSale ${~~props.filter.maxSale} <= ${~~item.salePrice}`);
        if (~~props.filter.maxSale >= ~~item.salePrice) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`maxSale ${filtered ? 'true' : 'false'}`);
      }
      console.log('minacres')
      console.log(props.filter.minAcres);
      console.log(item.acreage);
      if (~~props.filter.minAcres && ~~item.acreage) {
        console.log(`minAcres ${~~props.filter.minAcres} <= ${~~item.acreage}`);
        if (~~props.filter.minAcres <= ~~item.acreage) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`minAcres ${filtered ? 'true' : 'false'}`);
      }
      console.log('maxacres')
      if (~~props.filter.maxAcres && ~~item.acreage) {
        console.log(`maxAcres ${~~props.filter.maxAcres} >= ${~~item.acreage}`);
        if (~~props.filter.maxAcres >= ~~item.acreage) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`maxAcres ${filtered ? 'true' : 'false'}`);
      }
      console.log('no more acres')
      if (~~props.filter.minSqft && item.sqft) {
        if (~~props.filter.minSqft <= ~~item.minSqft) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`minSqft ${filtered ? 'true' : 'false'}`);
      }

      if (~~props.filter.maxSqft && item.sqft) {
        if (~~props.filter.maxSqft >= ~~item.sqft) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`maxSqft ${filtered ? 'true' : 'false'}`);
      }

      if (~~props.filter.dateFrom) {
        if (new Date(props.filter.dateFrom) >= new Date(item.saleDate)) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`dateFrom ${filtered ? 'true' : 'false'}`);
      }

      if (~~props.filter.dateTo) {
        if (new Date(props.filter.dateTo) <= new Date(item.dateTo)) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`dateTo ${filtered ? 'true' : 'false'}`);
      }

      if (props.filter.apn) {
        if (String(item.apn).indexOf(String(props.filter.apn)) + 1) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`apn ${filtered ? 'true' : 'false'}`);
      }

      if (props.filter.city) {
        if (String(item.city).indexOf(String(props.filter.city)) + 1) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`city ${filtered ? 'true' : 'false'}`);
      }

      if (props.filter.county) {
        if (String(item.county).indexOf(String(props.filter.county)) + 1) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`county ${filtered ? 'true' : 'false'}`);
      }

      if (props.filter.address) {
        if (String(item.address).indexOf(String(props.filter.address)) + 1) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`address ${filtered ? 'true' : 'false'}`);
      }

      if (props.filter.plantings) {
        if (String(item.plantings).indexOf(String(props.filter.plantings)) + 1) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`plantings ${filtered ? 'true' : 'false'}`);
      }

      if (props.filter.openGround) {
        if (String(item.openGround).indexOf(String(props.filter.openGround)) + 1) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`openGround ${filtered ? 'true' : 'false'}`);
      }

      if(props.filter.category) {
        if(item.category == props.filter.category) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`category ${filtered ? 'true' : 'false'}`);
      }

      if(props.filter.subcategory) {
        if(item.subcategory == props.filter.subcategory) {
          filtered = true;
        } else {
          filtered = false;
        }
        console.log(`subcategory ${filtered ? 'true' : 'false'}`);
      }

      if(item.propertyType != props.filter.propertyType) {
        filtered = false;
      }
      console.log(`filter finished ${filtered}`);
      console.log('');
      return filtered;
    };

    const fItems = ref(props.items.filter(filterItems));

    const getLabel = (item) => {
      const itemIndex = String(props.selectedItems.indexOf(item.id) + 1);
      return itemIndex !== "0"
        ? { text: itemIndex, color: "white", TAGFORFONTSIZE: "18pt" }
        : "";
    };

    const selectItem = (item) => {
      emit("updateSelectedItem", item, true);
    };

    const selectMarker = (item) => {
      // console.log(item);
      setCurrentSelected(item);
      if (item.id) {
        propertyCardData.value.id = String(item.id);
      }
      propertyCardData.value.imageId = String(item.imageId);
      if (item.salePrice) {
        propertyCardData.value.salePrice = String(item.salePrice);
      } else {
        item.salePrice = '';
      }
      if (item.address) {
        propertyCardData.value.address = String(item.address);
      } else {
        item.address = '';
      }
      if (item.waterDistrict) {
        propertyCardData.value.otherData = String(item.waterDistrict);
      } else {
        item.waterDistrict = '';
      }
      if (item.saleDate) {
        propertyCardData.value.saleDate = String(item.saleDate);
      } else {
        item.saleDate = '';
      }
      if (item.acreage) {
        propertyCardData.value.acres = String(item.acreage);
      } else {
        item.acreage = '';
      }
      infoWinPos.value = item.position;
      infoWinOpen.value = true;
    };

    const currentLocation = ref({ lat: 36.3389032, lng: -119.6480173 });

    const geoLocation = () => {
      console.log('geo locate me'); 
      try {
        navigator.geolocation.getCurrentPosition((position) => {
          currentLocation.value = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });

      } catch(e) {
        console.log('use default location');
      }
    };

    //*
    onMounted(() => {
      console.log(`mounted mapData, perform initial geoLocate: ${props.items.length}`);
      geoLocation();
      console.log('filter items');
      console.log(props.items);
      fItems.value = props.items.filter(filterItems);
      emit("geoCodeCheck");
    });
    /*/

    onUpdated(() => {
      console.log('update mapData, perform geoLocate');
      geoLocation();
      console.log('filter items');
      console.log(props.items);
      fItems.value = props.items.filter(filterItems);
      emit("geoCodeCheck");
    });
    //*/

    watch(props.filter, () => {
      console.log('update filter');
      fItems.value = props.items.filter(filterItems);
    });

    watch(props.items, () => {
      console.log('watch props items in mapData');
      console.log(props.items.length);
      fItems.value = props.items.filter(filterItems);
      emit("geoCodeCheck");
    });

    const propertyCardData = ref({
      id: null,
      imageId: null,
      address: "No Address",
      otherData: "N/A",
      salePrice: "No Price",
      acres: "N/A",
    });
    
    const selectItemFromId = (itemID) => {
      console.log('select item');
      const item = props.items.reduce((i,j) => {
        if(j.id == itemID) {
          return j;
        }
        return i;
      },null);
      if(item) {
        selectItem(item);
      }
    }

    return {
      selectItemFromId,
      selectMarker,
      propertyCardData,
      infoWinOpen,
      infoWinPos,
      infoOptions,
      defaultMarker,
      selectedMarker,
      getLabel,
      currentLocation,
      selectItem,
      filterItems,
      selectedRows,
      search,
      currentSelected,
      currentHover,
      setCurrentHover,
      handleInfoWindowClose,
      showContextMenu,
      contextMenuItems,
      contextMenuShown,
      contextMenuX,
      contextMenuY,
      fItems,
    };
  },
});
</script>
<style lang="scss" scoped>
.propertyCard {
  width: 600px;
  background:white;
}
.propertyCard:focus {
  background:white !important;
}
</style>
