<template>
<v-form>
  <v-container>
    <v-row>
      <v-col cols="auto">
        Price
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-text-field
          outlined
          dense
          label="Min $"
          v-model="data.minSale"
        ></v-text-field>
      </v-col>
      <v-col cols="auto" class="mx-0 pl-2 pr-1">
      <span>__</span> <!-- DO NOT REMOVE - THERE ARE TWO UNDERSCORES HERE!-->
      </v-col>
      <v-col cols="auto" class="pr-0">
        <v-text-field
          outlined
          dense
          label="Max $"
          v-model="data.maxSale"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        Acreage
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-text-field
          outlined
          dense
          label="Min Acres"
          v-model="data.minAcres"
        ></v-text-field>
      </v-col>
      <v-col cols="auto" class="mx-0 pl-2 pr-1">
      <span>__</span> <!-- DO NOT REMOVE - THERE ARE TWO UNDERSCORES HERE!-->
      </v-col>
      <v-col cols="auto" class="pr-0">
        <v-text-field
          outlined
          dense
          label="Max Acres"
          v-model="data.maxAcres"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        Total Sqft
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-text-field
          outlined
          dense
          label="Min Sqft"
          v-model="data.minSqft"
        ></v-text-field>
      </v-col>
      <v-col cols="auto" class="mx-0 pl-2 pr-1">
      <span>__</span> <!-- DO NOT REMOVE - THERE ARE TWO UNDERSCORES HERE!-->
      </v-col>
      <v-col cols="auto" class="pr-0">
        <v-text-field
          outlined
          dense
          label="Max Sqft"
          v-model="data.maxSqft"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-combobox clearable v-model="data.apn" label="APN" :items="filterLists.apn" outlined dense class="m-2"></v-combobox>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-combobox clearable v-model="data.city" label="City" :items="filterLists.city" outlined dense class="m-2"></v-combobox>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-combobox clearable v-model="data.county" label="County" :items="filterLists.county" outlined dense class="m-2"></v-combobox>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-combobox clearable v-model="data.address" label="Address" :items="filterLists.address" outlined dense class="m-2"></v-combobox>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        Date of Sale
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          outlined
          dense
          label="From"
          v-model="data.dateFrom"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          outlined
          dense
          label="To"
          v-model="data.dateTo"
        ></v-text-field>
      </v-col>
    </v-row>


    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-combobox clearable v-model="data.plantings" label="Plantings" :items="filterLists.plantings" outlined dense class="m-2"></v-combobox>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-combobox clearable v-model="data.waterDistrict" label="Water District" :items="filterLists.waterDistrict" outlined dense class="m-2"></v-combobox>
      </v-col>
    </v-row>
  </v-container>
</v-form>
</template>

<script>
import { defineComponent, reactive, watchEffect, watch } from 'vue';

export default defineComponent({
	name: 'filters',
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterLists: {
      type: Object,
      required: true,
    }
  },
  setup(props, { emit }) {
    const data = reactive({...props.filters})
    const todaysDate = 'MM/DD/YYYY';//change this to today's actual date
    watch(data, (newData) => {
      emit('updateFilter', newData);
      console.log('change');
      console.log(newData);
    });
    watchEffect(() => {
      console.log('watcheffect');
      data.value = {...props.filters};
    });
    return {
      todaysDate,
      data,
    };
  }
});
</script>
