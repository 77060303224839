<template>
     <v-dialog
       v-model="showDialog"
       width="500"
       @keydown.esc="fieldName=''; $emit('closeDialog')"
       @click:outside="fieldName=''; $emit('closeDialog')"
       @keydown.enter = "addField"
       >
       <v-card>
         <v-card-title light class="text-h5 white" style="color: #154985">
           Add Custom Field
         </v-card-title>
         <v-card-text>
           <v-row>
             <v-col cols="12">
               <v-progress-linear absolute :active="loading" indeterminate color="blue darken-4" value="100"></v-progress-linear>
             </v-col>
           </v-row>

           <v-row dense :class="{ errorSection: true, active: errorStatus !== '', inactive: errorStatus === '' }" style="overflow-x:auto; height: 50px;">
             <v-col cols="10" class="pl-4 pt-2" style="color: red; overflow-x:auto;" >
               {{ errorStatus !== '' ? `Error: ${errorStatus}` : '' }}
             </v-col>
             <v-col cols="1">
               <v-tooltip bottom color="red">
                 <template v-slot:activator="{ on, attrs }">
                   <v-btn v-show="errorStatus !== ''" icon @click="$emit('setError', '')" color="red darken-1" v-bind="attrs" v-on="on" style="overflow-x:auto;">
                     <v-icon>
                       mdi-close
                     </v-icon>
                   </v-btn>
                 </template>
                 Close Error
               </v-tooltip>
             </v-col>
           </v-row>
           <v-row>
             <v-col cols="12">
               <v-text-field autofocus v-model="fieldName" label="Field Name" outlined dense hide-details class="pa-1 ma-1" ></v-text-field>
               <v-select v-model="fieldType" label="Field Type" :items="fieldTypes" outlined dense class="pa-2 m-1"></v-select>
             </v-col>
           </v-row>
         </v-card-text>
         <v-divider></v-divider>

         <v-card-actions>
           <v-btn
             color="secondary"
             text
             @click="fieldName=''; $emit('closeDialog')"
             >
             Cancel
           </v-btn>
           <v-spacer></v-spacer>
           <v-btn
             color="primary"
             text
             @click="addField"
             >
             Add Field
           </v-btn>
         </v-card-actions>
       </v-card>
     </v-dialog>
</template>

<script>
  import { defineComponent, computed, ref, onMounted, watch } from 'vue';
  export default defineComponent({
    name: 'addCustomField',
    props: {
      dataSet: {
        type: String,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      errorStatus: {
        type: String,
        default: '',
      }
    },
    setup(props, { emit }) {
      const showDialog = computed(() => props.dataSet === '' ? false : true);
      const fieldName = ref('');
      const fieldType = ref('Number');
      const fieldTypes = ref(['Text', 'Number', 'Date', 'Money', 'Yes/No', 'Percent', 'CFJ']);
      const addField = () => {
        emit('addCustomField', props.dataSet, fieldName.value, fieldType.value );
      };
      onMounted(() => { console.log('mounted'); fieldName.value = '';})
      watch(showDialog, () => {
        fieldName.value = '';
      });
      const doNothing = () => {
        console.log('doNothing');
        return false;
      }
      return {
        doNothing,
        fieldName,
        fieldType,
        fieldTypes,
        addField,
        showDialog,
      }
    }
});
</script>
<style lang="scss" scoped>
@keyframes inactive {
  from {height: 0px; opacity: 100; }  
  to {height: 25px; opacity: 0; }
}
.errorSection {
  height: 0px;
  opacity: 0;
}
.inactive {
  height: 0px;
  opacity: 0;
  animation-name: inactive;
  animation-duration: 200ms;
}
.active {
  animation-name: inactiveError;
  animation-duration: 200ms;
  height: 25px;
  opacity: 100;
}
@keyframes inactiveError {
  from {height: 0px; opacity: 0; }  
  to {height: 25px; opacity: 100; }
}

</style>
